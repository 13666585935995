import {
    UserOutlined
} from '@ant-design/icons';


export const navDealer = [
    {
        key: '5',
        icon: <UserOutlined />,
        label: 'Dealers',
        to: '/Evsystem/Dealers',
    },
];