import React, { useEffect } from "react";
import Navbar from "../components/Navbar/Header";
import Footer from "../components/Footer/Footer";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Autoplay } from "swiper/modules";
// import { Navigation, Pagination, Scrollbar } from "swiper/modules";
// import Carousel from "react-multi-carousel";
// import "swiper/swiper-bundle.css";

const Home = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `${process.env.PUBLIC_URL}/js/designesia.js`; // Use the correct path to your script
    script.defer = true;
    document.body.appendChild(script);
    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const data = [
    {
      id: 1,
      href: "https://www.wavetec.com/case-studies/liberty-regional-medical-center/",
      img: "images/slider/6.png",
      title: "Liberty Regional Medical Center",
      icon_img: "assets/img/liberty regional medical center logo.jpg",
      description:
        "Liberty Regional Medical Center implemented the Wavetec Queuing System to revolutionize service delivery, streamline patient flow, and reduce wait times.",
    },
    {
      id: 2,
      href: "https://www.wavetec.com/case-studies/milwaukee-brewers/",
      img: "images/slider/2.png",
      title: "Milwaukee Brewers",
      icon_img: "assets/img/Milwaukee_Brewers_logo.png",
      description:
        "Milwaukee Brewers implemented the Wavetec UNO-Q Linear Queue System, aiming to streamline operations and elevate customer satisfaction.",
    },
    {
      id: 3,
      href: "https://www.wavetec.com/case-studies/mars-group/",
      img: "images/slider/3.png",
      title: "Mars Group",
      icon_img: "assets/img/mars-brand.png",
      description:
        "MARS, a leading retail group, operates a chain of M&M stores across the United States. MARS, a leading retail group,",
    },
    {
      id: 4,
      href: "https://www.wavetec.com/case-studies/kohls/",
      img: "images/slider/4.png",
      title: "Kohls",
      icon_img: "assets/img/Kohl_s.png",
      description:
        "By implementing Wavetec's UNO-Q, Kohl’s transformed its retail operations, ensuring a smoother, more efficient, and satisfying customer journey.",
    },
    {
      id: 5,
      href: "https://www.wavetec.com/case-studies/northgate/",
      img: "images/slider/5.png",
      title: "Northgate",
      icon_img: "assets/img/Northgate.png",
      description:
        "Northgate, America’s leading Hispanic Supermarket Chain, Partners With Wavetec To Enhance Customer Experience Across All Locations",
    },
  ];
  // const stepper = useSelector((state) => {
  //   console.log(state.stepper, "state data---");
  //   return state.stepper;
  // });
  // const [count, setCount] = useState(1);
  // const dispatch = useDispatch();

  // const navigate = useNavigate();

  // const updateVal = (item) => {
  //   console.log(item, "update item");

  //   dispatch(setStepper(item));
  // };

  // const handleNavigate = () => {
  //   let newval = count + 1;
  //   console.log(newval, "new wa;;;");
  //   setCount(newval);
  //   // let val = count;

  //   // console.log(val, "value----");
  //   // navigate("/contact-us");
  //   updateVal(newval);
  // };

  const slidesData = [
    {
      backgroundImage: "/images/slider/1.webp",
      subtitle: "Most Innovative Award Winner",
      title: "EV-125",
      teaser: "Drive Towards Sustainibilty",
      specs: [
        { icon: "icofont-map-pins", value: "110 KM", label: "Range" },
        { icon: "icofont-dashboard", value: "80 Km/h", label: "Top Speed" },
        {
          icon: "icofont-thunder-light",
          value: "2000 Watt",
          label: "Motor Power",
        },
      ],
      buttonText: "View Specifications",
      buttonLink: "vehicle-single-1.html",
      image: "/images/misc/ev125-right.webp",
    },
    {
      backgroundImage: "/images/slider/1.webp",
      subtitle: "Most Innovative Award Winner",
      title: "EV-1",
      teaser: "Control Your Destiny",
      specs: [
        { icon: "icofont-map-pins", value: "110 KM", label: "Range" },
        { icon: "icofont-dashboard", value: "50 Km/h", label: "Top Speed" },
        {
          icon: "icofont-thunder-light",
          value: "1500 Watt",
          label: "Motor Power",
        },
      ],
      buttonText: "View Specifications",
      buttonLink: "vehicle-single-1.html",
      image: "/images/misc/ev1-right.webp",
    },

    {
      backgroundImage: "/images/slider/1.webp",
      subtitle: "Most Innovative Award Winner",
      title: "EV-500",
      teaser: "Where Sustainability Meets Adventure",
      specs: [
        { icon: "icofont-map-pins", value: "100 KM", label: "Range" },
        { icon: "icofont-dashboard", value: "100 Km/h", label: "Top Speed" },
        {
          icon: "icofont-thunder-light",
          value: "3000 Watt",
          label: "Motor Power",
        },
      ],
      buttonText: "View Specifications",
      buttonLink: "vehicle-single-1.html",
      image: "/images/misc/ev500-right.webp",
    },
  ];

  return (
    <>
      <div id="wrapper">
        <div class="float-text show-on-scroll">
          <span>
            <a href="#">Scroll to top</a>
          </span>
        </div>
        <div class="scrollbar-v show-on-scroll"></div>

        {/* <!-- page preloader begin --> */}
        {/* <div id="de-loader"></div> */}
        {/* <!-- page preloader close --> */}

        <Navbar />

        {/* <!-- content begin --> */}
        <div class="no-bottom no-top" id="content">
          <div id="top"></div>

          {/* Banner Hero section */}

          {/* Old Banner Section */}

          {/* <section className="section-dark text-light no-top no-bottom position-relative overflow-hidden z-1000">
            <div className="v-center">
              <Swiper
                modules={[Navigation, Pagination, Scrollbar, Autoplay]}
                spaceBetween={50}
                slidesPerView={1}
                navigation
                // autoplay={{
                //   delay: 3000, // Delay of 2 seconds (2000ms)
                //   disableOnInteraction: false, // Keeps autoplay running after user interaction
                // }}
                pagination={{
                  clickable: true,
                  // renderBullet: (index, className) => {
                  //   return `<span class="${className}">${index + 1}</span>`;
                  // },
                }}
                scrollbar={{ draggable: true }}
                loop={true}
                speed={1000}
              >
                {slidesData.map((slide, index) => (
                  <SwiperSlide key={index}>
                    <div
                      className="swiper-inner"
                      style={{
                        backgroundImage: `url(${slide.backgroundImage})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    >
                      <div className="sw-caption">
                        <div className="container">
                          <div className="row g-4 align-items-center">
                            <div className="spacer-double"></div>
                            <div className="col-lg-6">
                              <div className="spacer-single"></div>
                              <div className="sw-text-wrapper">
                                <div className="subtitle s2 mb-2">
                                  <i className="icofont-badge id-color me-2"></i>
                                  {slide.subtitle}
                                </div>
                                <h1 className="slider-title mb-2">
                                  {slide.title}
                                </h1>
                                <h3 className="slider-teaser mb-3">
                                  {slide.teaser}
                                </h3>
                                <div className="slider-extra">
                                  <div className="row mt-3 g-2 text-center">
                                    {slide.specs.map((spec, specIndex) => (
                                      <div className="col-4" key={specIndex}>
                                        <div className="bg-dark rounded-10px lh-1-2em p-2">
                                          <i
                                            className={`fs-40 mb-1 id-color ${spec.icon}`}
                                          ></i>
                                          <div className="fs-18 fw-bold">
                                            {spec.value}
                                          </div>
                                          <div className="fs-14 op-5">
                                            {spec.label}
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                                <div className="spacer-30"></div>
                                <a
                                  className="btn-main mb10 mb-3"
                                  href={slide.buttonLink}
                                >
                                  {slide.buttonText}
                                </a>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="slider-extra">
                                <img
                                  src={slide.image}
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="spacer-single"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </section> */}

          {/* New Banner Section */}

          <section class="jarallax">
            <img
              src="images/background/gradient-2.webp"
              class="jarallax-img"
              alt=""
            />
            <div class="container">
              <div class="row g-4 gx-5 align-items-center">
                <div class="spacer-single d-lg-none d-sm-block"></div>

                <div class="col-lg-6 relative">
                  <div class="relative z-index-1000">
                    {/* <p
                      class="text-dark mb-0 fw-600 wow fadeInUp"
                      data-wow-delay=".2s"
                    >
                      <i class="icofont-badge me-2"></i>Most Innovative Award
                      Winner
                    </p> */}
                    <div class="spacer-20"></div>
                    <h1 class="wow fadeInUp" data-wow-delay=".4s">
                      Making Electric Bikes Accessible For Everyone!
                    </h1>
                    <div class="col-lg-10">
                      <p class="wow fadeInUp" data-wow-delay=".6s">
                        Best tailored to Pakistan’s driving conditions. Our bike
                        offers top features for <b>Safety, Ease,</b> and
                        <b> Affordability.</b>
                      </p>
                    </div>
                    <div class="spacer-10"></div>
                    <a
                      class="btn-main wow fadeIn"
                      data-wow-delay=".8s"
                      href="/book-a-test-drive"
                    >
                      Request a Test Ride
                    </a>

                    {/* <div class="d-inline">
                      <span
                        class="btn-play-wrap wow fadeIn"
                        data-wow-delay=".8s"
                      >
                        <a
                          id="play-video"
                          class="video-play-button popup-youtube mt-20"
                          href="https://www.youtube.com/watch?v=C6rf51uHWJg"
                        >
                          <span></span>
                        </a>
                        <span>Play Video</span>
                      </span>
                    </div> */}
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="relative">
                    <div
                      class="p-2 pb-0 rounded-10 absolute bottom-0 text-center z-index-2 wow scaleIn"
                      data-wow-delay=".3s"
                    >
                      {/* <img
                        src="images/misc/battery-box.webp"
                        class="img-fluid rounded-10"
                        alt=""
                      />
                      <p class="small fw-600 mb-0 text-dark">Quick Charge</p> */}
                    </div>
                    <div
                      class="p-2 pb-0 rounded-10 absolute top-0 end-0 text-center wow scaleIn"
                      data-wow-delay=".6s"
                    >
                      {/* <img
                        src="images/misc/bike-meter.webp"
                        class="img-fluid rounded-10"
                        alt=""
                      />
                      <p class="small fw-600 mb-0 text-dark">Futuristic</p> */}
                    </div>
                    <img
                      src="images/misc/red-bike.webp"
                      class="img-fluid relative z-index-1 rounded-20px wow fadeInUp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Discover Products Section */}

          <section>
            <div class="container">
              <div class="row align-items-center g-4 gx-5">
                <div class="col-lg-8">
                  <div class="subtitle bg-grey text-dark wow fadeInUp mb-3">
                    Welcome
                  </div>
                  <h2 class="wow fadeInUp">
                    Explore, Experience, And Connect With Us
                  </h2>
                </div>
                <div class="col-lg-4 text-lg-start">
                  <a class="btn-text text-dark wow fadeInLeft" href="/ev-125">
                    Explore
                  </a>
                </div>
                <div class="col-lg-4 wow fadeInRight" data-wow-delay=".2s">
                  <div class="relative">
                    <i
                      class="bg-color text-light p-3 rounded-20px fs-56 mb-4 icofont-read-book"
                      onClick={() => (window.location.href = "/ev-125")}
                    ></i>
                    <h4>Discover Your Perfect Ride</h4>
                    <p>
                      Available in a range of striking colors to match your
                      style.
                    </p>
                  </div>
                </div>
                <div class="col-lg-4 wow fadeInRight" data-wow-delay=".4s">
                  <div class="relative">
                    <i
                      class="bg-color text-light p-3 rounded-20px fs-56 mb-4 icofont-phone"
                      onClick={() =>
                        (window.location.href = "/book-a-test-drive")
                      }
                    ></i>
                    <h4>Take A Test Ride</h4>
                    <p>
                      Experience the journey from your doorstep by booking a
                      Test Ride.
                    </p>
                  </div>
                </div>
                <div class="col-lg-4 wow fadeInRight" data-wow-delay=".6s">
                  <div class="relative">
                    <i
                      class="bg-color text-light p-3 rounded-20px fs-56 mb-4 icofont-building"
                      onClick={() => (window.location.href = "/dealers")}
                    ></i>
                    <h4>Meet Us Here</h4>
                    <p>
                      Locate us today to enjoy a unique and personalized
                      experience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Moving Banner */}

          {/* <section class="jarallax text-light pt30 pb30">
            <img
              src="images/background/gradient-3.webp"
              class="jarallax-img"
              alt=""
            />
            <div class="wow fadeInRight d-flex">
              <div class="de-marquee-list wow">
                <div class="d-item">
                  <span class="d-item-txt">Zero Emissions</span>
                  <span class="d-item-display">
                    <i class="d-item-block"></i>
                  </span>
                  <span class="d-item-txt">Lower Costs</span>
                  <span class="d-item-display">
                    <i class="d-item-block"></i>
                  </span>
                  <span class="d-item-txt">Instant Torque</span>
                  <span class="d-item-display">
                    <i class="d-item-block"></i>
                  </span>
                  <span class="d-item-txt">Regenerative Braking</span>
                  <span class="d-item-display">
                    <i class="d-item-block"></i>
                  </span>
                  <span class="d-item-txt">Quiet Operation</span>
                  <span class="d-item-display">
                    <i class="d-item-block"></i>
                  </span>
                  <span class="d-item-txt">Advanced Technology</span>
                  <span class="d-item-display">
                    <i class="d-item-block"></i>
                  </span>
                  <span class="d-item-txt">Longer Range</span>
                  <span class="d-item-display">
                    <i class="d-item-block"></i>
                  </span>
                  <span class="d-item-txt">Charging Flexibility</span>
                  <span class="d-item-display">
                    <i class="d-item-block"></i>
                  </span>
                  <span class="d-item-txt">Environmental Benefits</span>
                  <span class="d-item-display">
                    <i class="d-item-block"></i>
                  </span>
                  <span class="d-item-txt">Variety of Models</span>
                  <span class="d-item-display">
                    <i class="d-item-block"></i>
                  </span>
                </div>
              </div>
            </div>
          </section> */}

          {/* Why Choose Us - Our Advantages */}

          <section class="jarallax">
            <img
              src="images/background/gradient-2.webp"
              class="jarallax-img"
              alt=""
            />
            <div class="container">
              <div class="row g-4 gx-5">
                <div class="col-lg-4">
                  <div class="subtitle bg-white text-dark wow fadeInUp mb-3">
                    Drive Electric
                  </div>
                  <h2 class="wow fadeInUp">
                    Constructing The Sustainable Future, Electrifying
                    Innovation!
                  </h2>
                </div>

                <div class="col-lg-4">
                  <h4 class="mb-4 wow fadeInRight">Why choose us</h4>
                  <ol class="ol-style-1">
                    <li class="wow fadeInUp" data-wow-delay=".2s">
                      <span className="choose-us-heading">
                        Advanced Technology:{" "}
                      </span>
                      Powered by lithium-ion batteries, efficient motor power
                      and higher battery capacity for superior performance.
                    </li>
                    <li class="wow fadeInUp" data-wow-delay=".4s">
                      <span className="choose-us-heading">
                        Affordable Excellence:{" "}
                      </span>
                      Incorporated with latest features, all at an accessible
                      price, delivering exceptional bike riding experience.
                    </li>
                    <li class="wow fadeInUp" data-wow-delay=".6s">
                      <span className="choose-us-heading">
                        Customer-Centric Service:{" "}
                      </span>
                      Providing support and assistance at every stage of your
                      journey to meet and exceed your expectations.
                    </li>
                  </ol>
                </div>

                <div class="col-lg-4">
                  <h4 class="mb-4 wow fadeInRight">Our Advantages</h4>
                  <ol class="ol-style-1 dark">
                    <li class="wow fadeInUp" data-wow-delay=".2s">
                      <span className="choose-us-heading">
                        Reliable and Safe:{" "}
                      </span>
                      Built-in app allows you to track your bike’s location, and
                      a button to shut your engine, ensuring security and peace
                      of mind.
                    </li>
                    <li class="wow fadeInUp" data-wow-delay=".4s">
                      <span className="choose-us-heading">
                        Budget-Friendly:{" "}
                      </span>
                      Enjoy eco-friendly electric biking solutions that are easy
                      on both the environment and your wallet.
                    </li>
                    <li class="wow fadeInUp" data-wow-delay=".6s">
                      <span className="choose-us-heading">
                        Easy and Simple:{" "}
                      </span>
                      Features like reverse mode, a digital meter, and a
                      convenient 10A charger provide fast charging in just 2
                      hours.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          {/*Advanced Features Section  */}

          <section>
            <div class="container">
              <div class="row g-4 align-items-center">
                <div class="col-lg-8">
                  <div class="subtitle bg-grey text-dark wow fadeInUp mb-3">
                    Spark Change
                  </div>
                  <h2 class="wow fadeInUp">Solutions that we offer</h2>
                </div>
                <div class="col-lg-4 text-lg-end">
                  {/* <a class="btn-text text-dark wow fadeInLeft" href="#">
                    Discover all features
                  </a> */}
                </div>
                <div class="col-lg-4">
                  <div
                    class="mb-4 relative wow fadeInRight"
                    data-wow-delay=".3s"
                  >
                    {/* <div class="absolute top-0 end-0">01</div> */}
                    <i class="bg-color text-light fs-48 p-2 absolute id-color icofont-thunder-light rounded-10px"></i>
                    <div class="pl-90">
                      <h4>Lithium-Ion Battery</h4>
                      <p class="mb-0">
                        Tested and proven technology with fast charging and
                        exceptional durability, offering higher capacity and
                        long-lasting performance.
                      </p>
                    </div>
                  </div>

                  <div
                    class="mb-4 relative wow fadeInRight"
                    data-wow-delay=".5s"
                  >
                    {/* <div class="absolute top-0 end-0">02</div> */}
                    <i class="bg-dark text-light fs-48 p-2 absolute id-color icofont-power rounded-10px"></i>
                    <div class="pl-90">
                      <h4>App Tracking</h4>
                      <p class="mb-0">
                        Monitor your bike’s location, overall statistics, and
                        the kill switch to disable the engine in case of theft
                        or emergencies.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <img
                    src="images/misc/6.1.webp"
                    class="img-fluid wow scaleIn"
                    alt=""
                  />
                </div>
                <div class="col-lg-4">
                  <div
                    class="mb-4 relative wow fadeInRight"
                    data-wow-delay=".3s"
                  >
                    {/* <div class="absolute top-0 end-0">01</div> */}
                    <i class="bg-color text-light fs-48 p-2 absolute id-color icofont-shield-alt rounded-10px"></i>
                    <div class="pl-90">
                      <h4>Efficient Motor Power</h4>
                      <p class="mb-0">
                        Delivering optimized performance for extended range,
                        efficient torque, and enhanced energy efficiency.
                      </p>
                    </div>
                  </div>

                  <div
                    class="mb-4 relative wow fadeInRight"
                    data-wow-delay=".5s"
                  >
                    {/* <div class="absolute top-0 end-0">02</div> */}
                    <i class="bg-dark text-light fs-48 p-2 absolute id-color icofont-automation rounded-10px"></i>
                    <div class="pl-90">
                      <h4>Advance Features</h4>
                      <p class="mb-0">
                        A high-build body, alloy rims, and tubeless tyres,
                        providing an optimized riding experience on challenging
                        roads.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Founder Message */}

          {/* <section class="relative overflow-hidden bg-dark text-light">
            <div class="container">
              <div class="row">
                <div class="col-lg-6 offset-lg-6">
                  <div class="ps-lg-5 ps-0">
                    <i class="icofont-quote-left id-color fs-40 mb-4 wow fadeInUp"></i>
                    <h3 class="mb-4 wow fadeInUp">
                      Electric vehicles: not just a mode of transportation, but
                      a symbol of our collective resolve to drive change and
                      usher in a new era of mobility
                    </h3>
                    <span class="wow fadeInUp">Donette Fondren, Founder</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-lg-6 absolute h-100 mh-500px top-0 bg-dark"
              data-bgimage="url(images/background/2.webp)"
            ></div>
          </section> */}

          {/* <section class="relative overflow-hidden mh-500px jarallax">
            <img
              src="images/background/gradient-2.webp"
              class="jarallax-img"
              alt=""
            />
            <div class="container">
              <div class="row">
                <div class="col-lg-6 offset-lg-6">
                  <div class="ps-lg-5 ps-0">
                    <i class="icofont-quote-left id-color fs-40 mb-4 wow fadeInUp"></i>
                    <h3 class="mb-4 wow fadeInUp">
                      Electric vehicles: not just a mode of transportation, but
                      a symbol of our collective resolve to drive change and
                      usher in a new era of mobility
                    </h3>
                    <span class="wow fadeInUp">Donette Fondren, Founder</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-lg-6 absolute h-100 mh-500px top-0 bg-dark"
              data-bgimage="url(images/background/2.webp)"
            ></div>
          </section> */}

          {/* Power ana Precision Section */}

          <section class="bg-dark section-dark text-light">
            <div class="container">
              <div class="row g-4 gx-5 align-items-center">
                <div class="col-lg-8">
                  <div class="subtitle bg-color text-light wow fadeInUp mb-3">
                    Consumer - Centric
                  </div>
                  <h2>Features to Make Your Ride Smoother and Comfortable</h2>
                </div>
                <div class="col-lg-4 text-lg-end">
                  {/* <a class="btn-text text-white" href="#">
                    Discover all features
                  </a> */}
                </div>
                <div class="col-lg-3">
                  <div
                    class="bg-white p-2 pb-0 mb-4 rounded-10 bottom-0 text-center z-index-2 wow scaleIn"
                    data-wow-delay=".0s"
                  >
                    <img
                      src="images/misc/bike-meter.webp"
                      class="img-fluid rounded-10 mb-2"
                      alt=""
                    />
                    <h5 class="no-bottom text-dark mb-3">Digital LED Meter</h5>
                    {/* <p class="small mb-1 text-dark mb-3">Sed nostrud anim elit.</p> */}
                  </div>
                  <p class="np-bottom">
                    Engaging LED display showing speed modes, battery alerts,
                    and kilometers covered.
                  </p>
                </div>

                <div class="col-lg-3">
                  <div
                    class="bg-white p-2 pb-0 mb-4 rounded-10 bottom-0 text-center z-index-2 wow scaleIn"
                    data-wow-delay=".2s"
                  >
                    <img
                      src="images/misc/disc-brake.webp"
                      class="img-fluid rounded-10 mb-2"
                      alt=""
                    />
                    <h5 class="no-bottom text-dark mb-3">Disc Brake</h5>
                    {/* <p class="small mb-1 text-dark mb-3">Sed nostrud anim elit.</p> */}
                  </div>
                  <p class="np-bottom">
                    Reliable brakes that ensure smooth stopping, with enhanced
                    stability for a safe ride every time.
                  </p>
                </div>

                <div class="col-lg-3">
                  <div
                    class="bg-white p-2 pb-0 mb-4 rounded-10 bottom-0 text-center z-index-2 wow scaleIn"
                    data-wow-delay=".4s"
                  >
                    <img
                      src="images/misc/tires.webp"
                      class="img-fluid rounded-10 mb-2"
                      alt=""
                    />
                    <h5 class="no-bottom text-dark mb-3">
                      Superior Performance Tyres
                    </h5>
                    {/* <p class="small mb-1 text-dark mb-3">Sed nostrud anim elit.</p> */}
                  </div>
                  <p class="np-bottom">
                    Tyres that guarantee stability, smooth handling, and optimal
                    ground clearance.
                  </p>
                </div>

                <div class="col-lg-3">
                  <div
                    class="bg-white p-2 pb-0 mb-4 rounded-10 bottom-0 text-center z-index-2 wow scaleIn"
                    data-wow-delay=".6s"
                  >
                    <img
                      src="images/misc/battery-box.webp"
                      class="img-fluid rounded-10 mb-2"
                      alt=""
                    />
                    <h5 class="no-bottom text-dark mb-3">Waterproof Battery</h5>
                    {/* <p class="small mb-1 text-dark">Sed nostrud anim elit.</p> */}
                  </div>
                  <p class="np-bottom">
                    Protects the battery from water and allowing you to ride
                    confidently in the rain.
                  </p>
                </div>
              </div>
            </div>
          </section>

          {/* <section class="jarallax">
            <img
              src="images/background/gradient-2.webp"
              class="jarallax-img"
              alt=""
            />
            <div class="container">
              <div class="row g-4 gx-5 align-items-center">
                <div class="col-lg-12">
                  <div class="subtitle bg-color text-light wow fadeInUp mb-3">
                    Autoev Technology
                  </div>
                  <h2 class="wow fadeInUp">Power &amp; Precision</h2>
                </div>
                <div class="col-lg-3">
                  <div
                    class="bg-white p-2 pb-0 mb-4 rounded-10 bottom-0 text-center z-index-2 wow scaleIn"
                    data-wow-delay=".0s"
                  >
                    <img
                      src="images/misc/p1.webp"
                      class="img-fluid rounded-10 mb-2"
                      alt=""
                    />
                    <h5 class="no-bottom text-dark">Modern Headlamp</h5>
                    <p class="small mb-1 text-dark">Sed nostrud anim elit.</p>
                  </div>
                  <p class="np-bottom">
                    Minim aliqua non incididunt irure laboris exercitation
                    laborum cillum non cupidatat magna tempor.
                  </p>
                </div>

                <div class="col-lg-3">
                  <div
                    class="bg-white p-2 pb-0 mb-4 rounded-10 bottom-0 text-center z-index-2 wow scaleIn"
                    data-wow-delay=".2s"
                  >
                    <img
                      src="images/misc/p2.webp"
                      class="img-fluid rounded-10 mb-2"
                      alt=""
                    />
                    <h5 class="no-bottom text-dark">Spacious Seat</h5>
                    <p class="small mb-1 text-dark">Sed nostrud anim elit.</p>
                  </div>
                  <p class="np-bottom">
                    Minim aliqua non incididunt irure laboris exercitation
                    laborum cillum non cupidatat magna tempor.
                  </p>
                </div>

                <div class="col-lg-3">
                  <div
                    class="bg-white p-2 pb-0 mb-4 rounded-10 bottom-0 text-center z-index-2 wow scaleIn"
                    data-wow-delay=".4s"
                  >
                    <img
                      src="images/misc/p3.webp"
                      class="img-fluid rounded-10 mb-2"
                      alt=""
                    />
                    <h5 class="no-bottom text-dark">Large Wheels</h5>
                    <p class="small mb-1 text-dark">Sed nostrud anim elit.</p>
                  </div>
                  <p class="np-bottom">
                    Minim aliqua non incididunt irure laboris exercitation
                    laborum cillum non cupidatat magna tempor.
                  </p>
                </div>

                <div class="col-lg-3">
                  <div
                    class="bg-white p-2 pb-0 mb-4 rounded-10 bottom-0 text-center z-index-2 wow scaleIn"
                    data-wow-delay=".6s"
                  >
                    <img
                      src="images/misc/p4.webp"
                      class="img-fluid rounded-10 mb-2"
                      alt=""
                    />
                    <h5 class="no-bottom text-dark">Quick Charge</h5>
                    <p class="small mb-1 text-dark">Sed nostrud anim elit.</p>
                  </div>
                  <p class="np-bottom">
                    Minim aliqua non incididunt irure laboris exercitation
                    laborum cillum non cupidatat magna tempor.
                  </p>
                </div>
              </div>
            </div>
          </section> */}

          {/* Carousel Section */}

          {/* <section class="jarallax">
            <img
              src="images/background/gradient-2.webp"
              class="jarallax-img"
              alt=""
            />
            <div class="container">
              <div class="row g-4 gx-5 align-items-center">
                <div class="col-lg-12">
                  <div class="subtitle bg-color text-light wow fadeInUp mb-3">
                    Autoev Technology
                  </div>
                  <h2 class="wow fadeInUp">Product Features</h2>
                </div>
                <div className="row mb-4 gx-5">
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <Carousel
                      additionalTransfrom={0}
                      arrows={true}
                      autoPlaySpeed={3000}
                      centerMode={false}
                      className=""
                      containerClass="carousel-container"
                      dotListClass=""
                      draggable
                      focusOnSelect={false}
                      infinite={true}
                      itemClass="carousel-item-boxes"
                      keyBoardControl
                      minimumTouchDrag={80}
                      pauseOnHover
                      renderArrowsWhenDisabled={false}
                      renderButtonGroupOutside
                      renderDotsOutside={false}
                      responsive={{
                        desktop: {
                          breakpoint: {
                            max: 3000,
                            min: 1024,
                          },
                          items: 4,
                          partialVisibilityGutter: 0,
                        },
                        mobile: {
                          breakpoint: {
                            max: 767,
                            min: 0,
                          },
                          items: 1,
                          partialVisibilityGutter: 0,
                        },
                        tablet: {
                          breakpoint: {
                            max: 1024,
                            min: 464,
                          },
                          items: 1,
                          partialVisibilityGutter: 30,
                        },
                      }}
                      rewind={false}
                      rewindWithAnimation={false}
                      rtl={false}
                      shouldResetAutoplay
                      showDots={false}
                      sliderClass=""
                      slidesToSlide={1}
                      swipeable
                    >
                      {data.map((item, index) => {
                        return (
                          <>
                            <div className="col-sm d-md-flex align-items-md-stretch ">
                              <a className="card-link d-md-flex">
                                <div className="card shadow">
                                  <div class="image-wrapper">
                                    <img
                                      src={item.img}
                                      className="card-img-top img-fluid"
                                      alt="example"
                                    />
                                  </div>
                                </div>
                              </a>
                            </div>
                          </>
                        );
                      })}
                    </Carousel>
                  </div>
                </div>
              </div>
            </div>
          </section> */}

          {/* Client reviews */}

          <section>
            <div class="container">
              <div class="row align-items-center">
                <div class="col-lg-8">
                  <div class="subtitle wow fadeInUp mb-3">Testimonials</div>
                  <h2 class="wow fadeInUp" data-wow-delay=".2s">
                    Our Happy Customers
                  </h2>
                  <div class="spacer-single"></div>
                </div>
                <div class="col-lg-4 text-lg-end">
                  {/* <a class="btn-text text-dark" href="#">
                    More testimonials
                  </a> */}
                </div>
              </div>
            </div>
            <div class="container-fluid">
              <div class="row">
                <div
                  class="owl-carousel owl-theme wow fadeInUp"
                  id="testimonial-carousel"
                >
                  <div class="item">
                    <div class="de_testi s2">
                      <blockquote>
                        <div class="de-rating-ext absolute top-20px end-30px">
                          <span class="d-stars">
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                          </span>
                        </div>
                        <div class="de_testi_by">
                          {/* <img
                            class="circle"
                            alt=""
                            src="images/testimonial/1.webp"
                          /> */}
                          <div>Jahanghir Shaikh.</div>
                        </div>
                        <p>
                          "The bike offers great torque, a smooth ride, good
                          speed, and impressive range."
                        </p>
                      </blockquote>
                    </div>
                  </div>
                  <div class="item">
                    <div class="de_testi s2">
                      <blockquote>
                        <div class="de-rating-ext absolute top-20px end-30px">
                          <span class="d-stars">
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                          </span>
                        </div>
                        <div class="de_testi_by">
                          {/* <img
                            class="circle"
                            alt=""
                            src="images/testimonial/2.webp"
                          /> */}
                          <div>
                            M.Saif Baig.
                            {/* <span>developer</span> */}
                          </div>
                        </div>
                        <p>
                          "I drive the bike daily for upto 108 km, and it has
                          been a very smooth ride. The bike offers excellent
                          torque, making it easy to handle in tight spaces. The
                          automatic drive eliminates the need to about changing
                          gears. I also really appreciate the reverse feature,
                          which requires zero effort especially on steep
                          surfaces. "
                        </p>
                      </blockquote>
                    </div>
                  </div>
                  <div class="item">
                    <div class="de_testi s2">
                      <blockquote>
                        <div class="de-rating-ext absolute top-20px end-30px">
                          <span class="d-stars">
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                          </span>
                        </div>
                        <div class="de_testi_by">
                          {/* <img
                            class="circle"
                            alt=""
                            src="images/testimonial/3.webp"
                          /> */}
                          <div>
                            Samad Abbas.
                            {/* <span>developer</span> */}
                          </div>
                        </div>
                        <p>
                          "My experience of switching to an electric bike has
                          been great. It has truly made my life easier, and I
                          find it better than a traditional engine bike. I love
                          the great torque, and the fact that it's so effortless
                          to ride because there are no gears.."
                        </p>
                      </blockquote>
                    </div>
                  </div>
                  <div class="item">
                    <div class="de_testi s2">
                      <blockquote>
                        <div class="de-rating-ext absolute top-20px end-30px">
                          <span class="d-stars">
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                          </span>
                        </div>
                        <div class="de_testi_by">
                          {/* <img
                            class="circle"
                            alt=""
                            src="images/testimonial/4.webp"
                          /> */}
                          <div>
                            M.Jaffar.
                            {/* <span>developer</span> */}
                          </div>
                        </div>
                        <p>
                          "I love that the bike is noiseless, has excellent road
                          grip, and is overall easy to use and simple to ride."
                        </p>
                      </blockquote>
                    </div>
                  </div>

                  {/* <div class="item">
                    <div class="de_testi s2">
                      <blockquote>
                        <div class="de-rating-ext absolute top-20px end-30px">
                          <span class="d-stars">
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                          </span>
                        </div>
                        <div class="de_testi_by">
                          <img
                            class="circle"
                            alt=""
                            src="images/testimonial/5.webp"
                          />{" "}
                          <div>
                            Carlos R.<span>developer</span>
                          </div>
                        </div>
                        <p>
                          "Being an early adopter of electric vehicles, I've
                          seen the technology evolve rapidly. Today's electric
                          cars are more advanced, affordable, and accessible
                          than ever before."
                        </p>
                      </blockquote>
                    </div>
                  </div> */}
                  {/* <div class="item">
                    <div class="de_testi s2">
                      <blockquote>
                        <div class="de-rating-ext absolute top-20px end-30px">
                          <span class="d-stars">
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                          </span>
                        </div>
                        <div class="de_testi_by">
                          <img
                            class="circle"
                            alt=""
                            src="images/testimonial/6.webp"
                          />{" "}
                          <div>
                            Edward B.<span>developer</span>
                          </div>
                        </div>
                        <p>
                          "Living in a city with strict emissions regulations,
                          owning an electric vehicle has been a lifesaver. No
                          more worrying about fines or restrictions, just clean
                          and worry-free driving."
                        </p>
                      </blockquote>
                    </div>
                  </div> */}

                  {/* <div class="item">
                    <div class="de_testi s2">
                      <blockquote>
                        <div class="de-rating-ext absolute top-20px end-30px">
                          <span class="d-stars">
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                          </span>
                        </div>
                        <div class="de_testi_by">
                          <img
                            class="circle"
                            alt=""
                            src="images/testimonial/8.webp"
                          />{" "}
                          <div>
                            Bryan G.<span>developer</span>
                          </div>
                        </div>
                        <p>
                          "Being able to charge my electric vehicle at home has
                          been a game-changer. It's incredibly convenient and
                          fits seamlessly into my daily routine."
                        </p>
                      </blockquote>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </section>

          {/* Customer range section */}

          {/* <section class="bg-dark section-dark text-light pt60 pb60 jarallax">
            <img
              src="images/background/gradient-3.webp"
              class="jarallax-img"
              alt=""
            />
            <div class="container">
              <div class="row g-4">
                <div class="col-md-3 col-sm-6 mb-sm-30">
                  <div
                    class="de_count pl-50 fs-15 wow fadeInRight"
                    data-wow-delay=".0s"
                  >
                    <i class="icofont-users-alt-1 absolute fs-40 start-0 id-color mt-1"></i>
                    <h3 class="fs-40">
                      <span
                        class="timer fs-40"
                        data-to="28950"
                        data-speed="3000"
                      >
                        0
                      </span>
                      +
                    </h3>
                    Happy Customers
                  </div>
                </div>
                <div class="col-md-3 col-sm-6 mb-sm-30">
                  <div
                    class="de_count pl-50 fs-15 wow fadeInRight"
                    data-wow-delay=".2s"
                  >
                    <i class="icofont-thunder-light absolute fs-40 start-0 id-color mt-1"></i>
                    <h3 class="fs-40">
                      <span class="timer fs-40" data-to="240" data-speed="3000">
                        0
                      </span>
                      +
                    </h3>
                    Charger Stations
                  </div>
                </div>
                <div class="col-md-3 col-sm-6 mb-sm-30">
                  <div
                    class="de_count pl-50 fs-15 wow fadeInRight"
                    data-wow-delay=".4s"
                  >
                    <i class="icofont-badge absolute fs-40 start-0 id-color mt-1"></i>
                    <h3 class="fs-40">
                      <span class="timer fs-40" data-to="158" data-speed="3000">
                        0
                      </span>
                      +
                    </h3>
                    Skilled Technicians
                  </div>
                </div>
                <div class="col-md-3 col-sm-6 mb-sm-30">
                  <div
                    class="de_count pl-50 fs-15 wow fadeInRight"
                    data-wow-delay=".6s"
                  >
                    <i class="icofont-world absolute fs-40 start-0 id-color mt-1"></i>
                    <h3 class="fs-40">
                      <span class="timer fs-40" data-to="20" data-speed="3000">
                        0
                      </span>
                      +
                    </h3>
                    Countries
                  </div>
                </div>
              </div>
            </div>
          </section> */}

          <section class="jarallax">
            <img
              src="images/background/gradient-2.webp"
              class="jarallax-img"
              alt=""
            />
            <div class="container">
              <div class="row align-items-center g-4">
                <div class="col-lg-8">
                  <div class="subtitle bg-white wow fadeInUp mb-3">
                    Do you have
                  </div>
                  <h2 class="wow fadeInUp" data-wow-delay=".2s">
                    Any questions?
                  </h2>
                </div>
                <div class="col-lg-4 text-lg-start">
                  <a class="btn-text text-dark wow fadeInLeft" href="/faqs">
                    More questions
                  </a>
                </div>
              </div>

              <div class="row g-custom-4">
                <div class="col-md-6 wow fadeInUp">
                  <div class="accordion secondary">
                    <div class="accordion-section">
                      <div
                        class="accordion-section-title"
                        data-tab="#accordion-1"
                      >
                        How do I maintain my EV bike?
                      </div>
                      <div class="accordion-section-content" id="accordion-1">
                        <p>
                          Maintaining an EV bike is simple and hassle-free. Keep
                          your bike clean, ensure the battery is charged, and
                          avoid exposing it to wet areas while charging. Unlike
                          traditional engine bikes that need regular maintenance
                          of the engine, brakes, clutch, and gears, EV bikes
                          have fewer moving parts and require minimal care. Just
                          check your tyre pressure, brake condition, and battery
                          health periodically to ensure optimal performance and
                          a smooth ride. If you have any questions or need
                          assistance, feel free to call our customer service
                          center.
                        </p>
                      </div>
                      <div
                        class="accordion-section-title"
                        data-tab="#accordion-2"
                      >
                        How long is the battery warranty?
                      </div>
                      <div class="accordion-section-content" id="accordion-2">
                        <p>
                          The battery warranty for ELFA electric bikes in
                          Pakistan is 3 years or 40,000 KM, whichever comes
                          first. This warranty applies to issues specified by
                          the company in the warranty booklet. Any breach of the
                          regulations not covered under the warranty terms will
                          not be eligible for coverage.
                        </p>
                      </div>
                      {/* <div
                        class="accordion-section-title"
                        data-tab="#accordion-3"
                      >
                        How long does it take to charge?
                      </div>
                      <div class="accordion-section-content" id="accordion-3">
                        <p>
                          Charging times vary depending on the vehicle's battery
                          size, charging equipment, and charging rate. Level 1
                          charging (using a standard household outlet) typically
                          takes several hours to fully charge an EV.
                        </p>
                      </div> */}
                    </div>
                  </div>
                </div>

                <div class="col-md-6 wow fadeInUp">
                  <div class="accordion secondary">
                    <div class="accordion-section">
                      <div
                        class="accordion-section-title"
                        data-tab="#accordion-b-4"
                      >
                        How long do I need to charge my electric bike?
                      </div>
                      <div class="accordion-section-content" id="accordion-b-4">
                        <p>
                          Our current EV-125 variant has a battery capacity of
                          32Ah/72V, which requires approximately 2.3 units of
                          electricity to fully charge. It can be charged in 2-3
                          hours using a company-provided charging plug.
                        </p>
                      </div>
                      <div
                        class="accordion-section-title"
                        data-tab="#accordion-b-5"
                      >
                        Is driving an EV bike easy?
                      </div>
                      <div class="accordion-section-content" id="accordion-b-5">
                        <p>
                          Yes, riding an EV bike is really easy, as it doesn’t
                          have a gear or clutch to adjust whenever you change
                          speeds. Our electric bike offers three speed
                          modes—Eco, City, and Sports—that you can switch
                          between with the push of a button.
                        </p>
                      </div>
                      {/* <div
                        class="accordion-section-title"
                        data-tab="#accordion-b-6"
                      >
                        Are electric vehicles suitable for cold climates?
                      </div>
                      <div class="accordion-section-content" id="accordion-b-6">
                        <p>
                          Electric vehicles can perform well in cold climates,
                          but extreme temperatures can affect battery
                          performance and range. Preheating the cabin while the
                          vehicle is still plugged in can help preserve battery
                          charge in cold weather. Some EVs also offer features
                          such as battery preconditioning to optimize
                          performance in cold temperatures.
                        </p>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="jarallax bg-color text-light pt30 pb30">
            <div class="wow fadeInRight d-flex">
              <div class="de-marquee-list wow">
                <div class="d-item">
                  <span class="d-item-txt">Zero Emissions</span>
                  <span class="d-item-display">
                    <i class="d-item-block bg-white"></i>
                  </span>
                  <span class="d-item-txt">Lower Costs</span>
                  <span class="d-item-display">
                    <i class="d-item-block bg-white"></i>
                  </span>
                  <span class="d-item-txt">Instant Torque</span>
                  <span class="d-item-display">
                    <i class="d-item-block bg-white"></i>
                  </span>
                  <span class="d-item-txt">Regenerative Braking</span>
                  <span class="d-item-display">
                    <i class="d-item-block bg-white"></i>
                  </span>
                  <span class="d-item-txt">Quiet Operation</span>
                  <span class="d-item-display">
                    <i class="d-item-block bg-white"></i>
                  </span>
                  <span class="d-item-txt">Advanced Technology</span>
                  <span class="d-item-display">
                    <i class="d-item-block bg-white"></i>
                  </span>
                  <span class="d-item-txt">Longer Range</span>
                  <span class="d-item-display">
                    <i class="d-item-block bg-white"></i>
                  </span>
                  <span class="d-item-txt">Charging Flexibility</span>
                  <span class="d-item-display">
                    <i class="d-item-block bg-white"></i>
                  </span>
                  <span class="d-item-txt">Environmental Benefits</span>
                  <span class="d-item-display">
                    <i class="d-item-block bg-white"></i>
                  </span>
                  <span class="d-item-txt">Variety of Models</span>
                  <span class="d-item-display">
                    <i class="d-item-block bg-white"></i>
                  </span>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* Ho */}

        {/* <!-- content close --> */}

        <Footer />
      </div>
    </>
  );
};

export default Home;
