import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Content } from 'antd/es/layout/layout';
import routes from '../routes';
import AppBreadcrumb from './AppBreadcrumb';

const AppContent = ({ colorBgContainer }) => {
    const loadingIcon = (
        <LoadingOutlined style={{ fontSize: 24, color: '#1890ff' }} spin />
    );

    return (
        <Content
            style={{
                margin: '80px 16px 0px 16px',
            }}
        >
            <AppBreadcrumb />
            <div
                style={{
                    flex:1,
                    display: 'block',
                    margin: '22px 16px 0px 16px',
                    padding: 10,
                    background: colorBgContainer,
                    borderRadius: '8px',
                    minHeight: 'calc(100vh - 175px)', // Adjust for header and footer height
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                }}
            >
                <Suspense
                    fallback={
                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                            <Spin indicator={loadingIcon} />
                        </div>
                    }
                >
                    <Routes>
                        {routes.map((route, idx) => {
                            return (
                                route.element && (
                                    <Route
                                        key={idx}
                                        path={route.path}
                                        exact
                                        name={route.name}
                                        element={
                                            route.provider ? (
                                                <route.provider>
                                                    <route.element />
                                                </route.provider>
                                            ) : (
                                                <route.element />
                                            )
                                        }
                                    />
                                )
                            );
                        })}
                        <Route path="*" element={<Navigate to="/Evsystem/404" replace />} />
                    </Routes>
                </Suspense>

            </div>
        </Content>
    );
};

export default React.memo(AppContent);
