import React, { useEffect, useState } from "react";
import Header from "../components/Navbar/Header";
import Footer from "../components/Footer/Footer";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const EV125 = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const images = [
    "images/misc/ev-125-main-red.webp",
    "images/misc/ev-125-main-black.webp",
    "images/misc/ev-125-main-Gray.webp",
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const styles = {
    carouselContainer: {
      position: "relative",
      width: "100%",
      overflow: "hidden",
      margin: "auto",
    },
    carouselTrack: {
      display: "flex",
      transition: "transform 0.5s ease-in-out",
    },
    image: {
      minWidth: "100%",
      flexShrink: 0,
    },
    button1: {
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      background: "rgba(0, 0, 0, 0.5)",
      color: "white",
      border: "none",

      cursor: "pointer",
      padding: "10px",
      zIndex: 10,
    },
    button2: {
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      background: "rgba(0, 0, 0, 0.5)",
      color: "white",
      border: "none",
      left: "95%",
      cursor: "pointer",
      padding: "10px",
      zIndex: 10,
    },
    prev: {
      left: "10px",
    },
    next: {
      right: "10px",
    },
  };
  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `${process.env.PUBLIC_URL}/js/designesia.js`; // Use the correct path to your script
    script.defer = true;
    document.body.appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <>
      <div id="wrapper">
        <div class="float-text show-on-scroll">
          <span>
            <a href="#">Scroll to top</a>
          </span>
        </div>
        <div class="scrollbar-v show-on-scroll"></div>
      </div>

      {/* <!-- page preloader begin --> */}
      {/* <div id="de-loader"></div> */}
      {/* <!-- page preloader close --> */}

      {/* <!-- header begin --> */}
      <Header />
      {/* <!-- header close --> */}

      {/* <!-- content begin --> */}
      <div class="no-bottom no-top" id="content">
        <div id="top"></div>

        <section id="subheader" class="jarallax text-light bg-dark">
          <img src="images/misc/5.2.webp" class="jarallax-img" alt="" />
          <div class="container relative z-index-1000">
            <div class="container relative z-index-1000">
              <div class="row align-items-center">
                <div class="col-lg-6">
                  <h5 class=" wow fadeInUp mb-2">
                    <span class="id-color">EV-125</span>
                  </h5>
                  <h1>Engineered To Thrive On Challenging Local Roads!</h1>

                  <div class="row mt-3 g-2 text-center">
                    <div class="col-3">
                      <div class="bg-dark rounded-10px lh-1-2em p-2">
                        <i class="fs-40 mb-1 id-color icofont-map-pins"></i>
                        <div class="fs-18 fw-bold">100 KM</div>
                        <div
                          class="fs-14 op-5"
                          style={{ minHeight: mobile ? "60px" : "0px" }}
                        >
                          Range
                        </div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="bg-dark rounded-10px lh-1-2em p-2">
                        <i class="fs-40 mb-1 id-color icofont-dashboard"></i>
                        <div class="fs-18 fw-bold">75 Km/h</div>
                        <div class="fs-14 op-5">Top Speed</div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="bg-dark rounded-10px lh-1-2em p-2">
                        <i class="fs-40 mb-1 id-color icofont-thunder-light"></i>
                        <div class="fs-18 fw-bold">2000 Watt</div>
                        <div class="fs-14 op-5">Motor Power</div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="bg-dark rounded-10px lh-1-2em p-2">
                        <i class="fs-40 mb-1 id-color icofont-thunder-light"></i>
                        <div class="fs-18 fw-bold">32AH / 72V</div>
                        <div class="fs-14 op-5">Battery Capacity</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div class="container">
            <div class="row g-4 align-items-center">
              <div class="col-lg-12 text-center">
                <div class="subtitle bg-grey text-dark wow fadeInUp mb-3">
                  Spark Change
                </div>
                <h1 class="mb-0">
                  Make Your Day To Day Ride <br /> Hassle-Free And Memorable
                </h1>
              </div>
              <div class="clearfix"></div>

              <div class="col-lg-8 offset-lg-2">
                <div class="relative">
                  <div style={styles.carouselContainer}>
                    <div
                      style={{
                        ...styles.carouselTrack,
                        transform: `translateX(-${currentIndex * 100}%)`,
                      }}
                    >
                      {images.map((src, index) => (
                        <img
                          key={index}
                          src={src}
                          class="img-fluid wow scaleIn"
                          alt={`Slide ${index + 1}`}
                          style={styles.image}
                        />
                      ))}
                    </div>
                    <button style={styles.button1} onClick={handlePrev}>
                      ❮
                    </button>
                    <button style={styles.button2} onClick={handleNext}>
                      ❯
                    </button>
                  </div>
                  <h3 className="text-center">
                    {currentIndex === 0
                      ? "Red"
                      : currentIndex === 1
                      ? "Black"
                      : "Silver"}
                  </h3>
                  {/* <img
                    src="images/misc/ev-125-main-red.webp"
                    class="img-fluid wow scaleIn"
                    alt=""
                  /> */}
                  {currentIndex == 0 && (
                    <>
                      <div
                        class="de-dot mt-20"
                        style={{ left: "21%", top: "25%" }}
                        href="#"
                      >
                        <div class="d-content bg-color text-light">
                          <h5>Digital Meter</h5>
                          Consolidated insights about your bike.
                        </div>
                      </div>
                      <div
                        class="de-dot mt-20"
                        style={{ left: "20%", top: "35%" }}
                        href="#"
                      >
                        <div class="d-content bg-color text-light">
                          <h5>LED Lights</h5>
                          Lights - Ensuring Low Power Consumption
                        </div>
                      </div>
                      <div
                        class="de-dot mt-20"
                        style={{ left: "4%", top: "66%" }}
                        href="#"
                      >
                        <div class="d-content bg-color text-light">
                          <h5>Alloy Rims - Long-Lasting</h5>
                          Tyre Performance to Withstand Challenging Roads
                        </div>
                      </div>
                      <div
                        class="de-dot mt-20"
                        style={{ left: "36%", top: "56%" }}
                        href="#"
                      >
                        <div class="d-content bg-color text-light">
                          <h5>Lithium-Ion Battery</h5>
                          Protected by metallic Waterproof box
                        </div>
                      </div>

                      <div
                        class="de-dot mt-20"
                        style={{ left: "70%", top: "65%" }}
                        href="#"
                      >
                        <div class="d-content bg-color text-light">
                          <h5>Disc Brakes</h5>
                          Ensure a Smooth Braking Experience.
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="jarallax">
          <img
            src="images/background/gradient-2.webp"
            class="jarallax-img"
            alt=""
          />
          {/* <div class="container">
            <div class="row g-4 align-items-center">
              <div class="col-lg-12 text-center">
                <div class="subtitle bg-white wow fadeInUp mb-3">
                  Tailored Made
                </div>
                <h2 class="mb-0">Power &amp; Precision</h2>
              </div>
              <div class="col-lg-6">
                <div
                  class="de-flex bg-dark text-light p-3 rounded-10 z-index-2 wow scaleIn"
                  data-wow-delay=".0s"
                >
                  <div>
                    <img
                      src="images/misc/p1.1.webp"
                      class="w-150px rounded-10"
                      alt=""
                    />
                  </div>
                  <div class="ms-4">
                    <h4 class="mb-1">Modern Headlamp</h4>
                    <p class="no-bottom">
                      Minim aliqua non incididunt irure laboris exercitation
                      laborum cillum non cupidatat.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div
                  class="de-flex bg-dark text-light p-3 rounded-10 z-index-2 wow scaleIn"
                  data-wow-delay=".2s"
                >
                  <div>
                    <img
                      src="images/misc/p2.2.webp"
                      class="w-150px rounded-10"
                      alt=""
                    />
                  </div>
                  <div class="ms-4">
                    <h4 class="mb-1">Spacious Seat</h4>
                    <p class="no-bottom">
                      Minim aliqua non incididunt irure laboris exercitation
                      laborum cillum non cupidatat.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div
                  class="de-flex bg-dark text-light p-3 rounded-10 z-index-2 wow scaleIn"
                  data-wow-delay=".4s"
                >
                  <div>
                    <img
                      src="images/misc/p3.3.webp"
                      class="w-150px rounded-10"
                      alt=""
                    />
                  </div>
                  <div class="ms-4">
                    <h4 class="mb-1">Large Wheels</h4>
                    <p class="no-bottom">
                      Minim aliqua non incididunt irure laboris exercitation
                      laborum cillum non cupidatat.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div
                  class="de-flex bg-dark text-light p-3 rounded-10 z-index-2 wow scaleIn"
                  data-wow-delay=".6s"
                >
                  <div>
                    <img
                      src="images/misc/p4.4.webp"
                      class="w-150px rounded-10"
                      alt=""
                    />
                  </div>
                  <div class="ms-4">
                    <h4 class="mb-1">Quick Charge</h4>
                    <p class="no-bottom">
                      Minim aliqua non incididunt irure laboris exercitation
                      laborum cillum non cupidatat.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div class="col-lg-12 text-center">
            <div class="subtitle bg-white text-dark wow fadeInUp mb-3">
              Detailed
            </div>
            <h2 class="mb-0">Advanced Features That We Offer</h2>
          </div>

          <section>
            <div class="container">
              <div class="row">
                <div class="col-lg-10 offset-lg-1">
                  <div class="site-timeline-content">
                    {/* <!-- Article --> */}
                    <div class="de-timeline-article odd">
                      <div class="site-center-line"></div>
                      <div class="content-left-container">
                        <div class="owl-single-dots owl-carousel owl-theme">
                          <div class="item">
                            <img src="images/timeline/1.1.webp" alt="" />
                          </div>
                          {/* <div class="item">
                            <img src="images/timeline/2.webp" alt="" />
                          </div>
                          <div class="item">
                            <img src="images/timeline/3.webp" alt="" />
                          </div> */}
                        </div>
                      </div>
                      <div class="content-right-container">
                        <div class="year__">
                          <div class="d-line"></div>
                          <h4 class="de-timeline-year">1-</h4>
                        </div>
                        <div class="content-right">
                          <h3 class="de-timeline-title">
                            3 Different Speed Modes
                          </h3>
                          <p>
                            <b>Eco:</b> Unlock the maximum efficiency and
                            extended battery life of the bike.
                          </p>
                          <p>
                            <b>City:</b> Ideal for daily commutes, providing you
                            optimal speed for navigating through traffic and
                            stops.
                          </p>
                          <p>
                            <b>Sports:</b> With its uninterrupted acceleration,
                            reach the bike’s highest speed.
                          </p>
                        </div>
                      </div>
                      <div class="meta-dot"></div>
                    </div>
                    {/* <!-- // Article --> */}
                    {/* <!-- Article --> */}
                    <div class="de-timeline-article even">
                      <div class="site-center-line"></div>
                      <div class="content-left-container">
                        <div class="year__">
                          <div class="d-line"></div>
                          <h4 class="de-timeline-year">2-</h4>
                        </div>
                        <div class="content-right">
                          <h3 class="de-timeline-title">
                            Lithium Power Cell Battery
                          </h3>
                          <p>
                            With 2,000 battery cycles, 2-hour fast charging, and
                            a range of up to 100 KM, to enjoy longer rides with
                            less time spent charging, making it easy for anyone
                            to sustain economic pressure, as our bikes cover 1
                            KM for just 1 rupee.
                          </p>
                        </div>
                      </div>
                      <div class="content-right-container">
                        <div class="owl-single-dots owl-carousel owl-theme">
                          <div class="item">
                            <img src="images/timeline/5.1.webp" alt="" />
                          </div>
                          {/* <div class="item">
                            <img src="images/timeline/5.webp" alt="" />
                          </div>
                          <div class="item">
                            <img src="images/timeline/6.webp" alt="" />
                          </div> */}
                        </div>
                      </div>
                      <div class="meta-dot"></div>
                    </div>
                    {/* <!-- // Article --> */}
                    {/* <!-- Article --> */}
                    <div class="de-timeline-article odd">
                      <div class="site-center-line"></div>
                      <div class="content-left-container">
                        <div class="owl-single-dots owl-carousel owl-theme">
                          <div class="item">
                            <img src="images/timeline/mobile-app.webp" alt="" />
                          </div>
                          {/* <div class="item">
                            <img src="images/timeline/8.webp" alt="" />
                          </div>
                          <div class="item">
                            <img src="images/timeline/9.webp" alt="" />
                          </div> */}
                        </div>
                      </div>
                      <div class="content-right-container">
                        <div class="year__">
                          <div class="d-line"></div>
                          <h4 class="de-timeline-year">3-</h4>
                        </div>
                        <div class="content-right">
                          <h3 class="de-timeline-title">Mobile App</h3>
                          <p>
                            Track the performance of your bike's range, speed
                            mode, distance, carbon emissions reduction, fuel
                            savings, battery status, driver statistics, and much
                            more.
                          </p>
                        </div>
                      </div>
                      <div class="meta-dot"></div>
                    </div>
                    {/* <!-- // Article --> */}
                    <div class="de-timeline-article even">
                      <div class="site-center-line"></div>
                      <div class="content-left-container">
                        <div class="year__">
                          <div class="d-line"></div>
                          <h4 class="de-timeline-year">4-</h4>
                        </div>
                        <div class="content-right">
                          <h3 class="de-timeline-title">Tubeless Tyres</h3>
                          <p>
                            Optimized for stability and smooth handling, our
                            bikes feature an 18-inch front wheel and 17-inch
                            rear wheel diameter, ensuring a comfortable ride on
                            challenging roads.
                          </p>
                        </div>
                      </div>
                      <div class="content-right-container">
                        <div class="owl-single-dots owl-carousel owl-theme">
                          <div class="item">
                            <img src="images/timeline/4.1.webp" alt="" />
                          </div>
                          {/* <div class="item">
                            <img src="images/timeline/5.webp" alt="" />
                          </div>
                          <div class="item">
                            <img src="images/timeline/6.webp" alt="" />
                          </div> */}
                        </div>
                      </div>
                      <div class="meta-dot"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>

        <section class="bg-grey">
          <div class="container">
            <div class="row g-4 align-items-center">
              <div class="col-lg-12 text-center">
                <div class="subtitle bg-white text-dark wow fadeInUp mb-3">
                  Detailed
                </div>
                <h2 class="mb-0">Specifications</h2>
              </div>
              <div class="clearfix"></div>
              <div class="col-md-8 offset-lg-2 wow fadeInUp">
                <div class="accordion">
                  <div class="accordion-section">
                    <div
                      class="accordion-section-title"
                      data-tab="#accordion-1"
                    >
                      <h4 class="mb-0 d-inline">Motor</h4>
                    </div>
                    <div class="accordion-section-content" id="accordion-1">
                      <ul>
                        <li>Motor: 2000 W</li>
                        <li>Maximum Range: 100 KM</li>
                      </ul>
                    </div>
                    <div
                      class="accordion-section-title"
                      data-tab="#accordion-2"
                    >
                      <h4 class="mb-0 d-inline">Battery</h4>
                    </div>
                    <div class="accordion-section-content" id="accordion-2">
                      <ul>
                        <li>Battery: Lithium-Ion Battery</li>
                        <li>Battery Capacity: 72V/32Ah</li>
                        <li>Charging Time: 2-3 hours</li>
                        <li>Charger: 10A</li>
                      </ul>
                    </div>
                    <div
                      class="accordion-section-title"
                      data-tab="#accordion-3"
                    >
                      <h4 class="mb-0 d-inline">Performance</h4>
                    </div>
                    <div class="accordion-section-content" id="accordion-3">
                      <ul>
                        <li>Top Speed: 75 km/h</li>
                        <li>Speed Modes: Eco, City, Sports &amp; Reverse</li>
                      </ul>
                    </div>
                    <div
                      class="accordion-section-title"
                      data-tab="#accordion-4"
                    >
                      <h4 class="mb-0 d-inline">Body</h4>
                    </div>
                    <div class="accordion-section-content" id="accordion-4">
                      <ul>
                        <li>Brakes: Front Drum & Rear Disc</li>
                        <li>Tyres: Tubeless</li>
                        <li>Alloy Rims: Front &amp; Rear</li>
                      </ul>
                    </div>
                    {/* <div
                      class="accordion-section-title"
                      data-tab="#accordion-5"
                    >
                      <h4 class="mb-0 d-inline">Dimensions</h4>
                    </div> */}
                    {/* <div class="accordion-section-content" id="accordion-5">
                      <ul>
                        <li>Length: 2,000 mm</li>
                        <li>Width: 800 mm</li>
                        <li>Height: 1,100 mm</li>
                        <li>Seat height: 800 mm</li>
                        <li>Ground clearance: 150 mm</li>
                        <li>Weight: 180 kg (396 lbs)</li>
                      </ul>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section class="jarallax">
          <img
            src="images/background/gradient-2.webp"
            class="jarallax-img"
            alt=""
          />
          <div class="container">
            <div class="row g-4 align-items-center">
              <div class="col-lg-12 text-center">
                <div class="subtitle text-dark wow fadeInUp mb-3">Play</div>
                <h2 class="mb-0">Video</h2>
              </div>
              <div class="clearfix"></div>
              <div class="col-lg-8 offset-lg-2">
                <div class="row g-4 gx-5">
                  <div class="col-lg-6">
                    <div class="relative">
                      <a
                        href="https://www.youtube.com/embed/36FjQAudDk8"
                        class="popup-youtube"
                      >
                        <span class="absolute start-20px fs-18 bottom-20px text-light fw-600">
                          <i class="icofont-play-alt-1 me-2 id-color"></i> Play
                          Video
                        </span>
                        <img
                          src="images/misc/1.webp"
                          class="img-fluid rounded-20px"
                          alt=""
                        />
                      </a>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="relative">
                      <a
                        href="https://www.youtube.com/embed/36FjQAudDk8"
                        class="popup-youtube"
                      >
                        <span class="absolute start-20px fs-18 bottom-20px text-light fw-600">
                          <i class="icofont-play-alt-1 me-2 id-color"></i> Play
                          Video
                        </span>
                        <img
                          src="images/misc/2.webp"
                          class="img-fluid rounded-20px"
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section class="bg-color text-light pt40 pb40">
          <div class="container">
            <div class="row g-4 align-items-center">
              <div class="col-lg-9">
                <h3 class="mb-1">It's a silent thriller, try it out!</h3>
              </div>

              <div class="col-lg-3 text-lg-end text-start">
                <a class="btn-main bg-dark" href="/book-a-test-drive">
                  Book a Test Ride
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* Content Close */}

      <Footer />
    </>
  );
};

export default EV125;
