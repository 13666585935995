import React, { useEffect } from "react";
import Header from "../components/Navbar/Header";
import Footer from "../components/Footer/Footer";
import { Link } from "react-router-dom";

const Dealers = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `${process.env.PUBLIC_URL}/js/designesia.js`; // Use the correct path to your script
    script.defer = true;
    document.body.appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  // Latitude and Longitude of the location
  const location = {
    lat: 25.02984836789683, // Latitude for Bahria Town Karachi
    lng: 67.30903777507513, // Longitude for Bahria Town Karachi
  };

  // This function opens Google Maps with the lat/lng passed as a destination
  const handleGetDirection = (lat, lng) => {
    const destination = `${lat},${lng}`;
    window.open(
      `https://www.google.com/maps/dir/?api=1&destination=${destination}`,
      "_blank"
    );
  };

  const dealers = [
    // {
    //   name: "Bahria Town (Muneeb & Co.) Karachi",
    //   address: "Shop # 01 B-142 Midway Commercial, Bahria Town Karachi",
    //   email: "contact@autoev.com",
    //   phone: "+929 333 9296",
    //   lat: 25.02984836789683,
    //   lng: 67.30903777507513,
    //   image: "images/dealers/1.webp",
    // },
    // {
    //   name: "AAJ Enterprises",
    //   address:
    //     "Shop bearing No.S-10, Shalimar Shopping Mall, Block-17 Gulistan-e-Johar",
    //   email: "aajenterprises2024@gmail.com",
    //   phone: "03123822183",
    //   lat: 24.908395, // Latitude for Gulshan-e-Iqbal
    //   lng: 67.11942, // Longitude for Gulshan-e-Iqbal
    //   image: "images/dealers/dealer-1.webp",
    //   shedule: "Mon - Fri",
    //   timings: "08.00 - 18.00",
    // },
    {
      name: "Hilal Motors",
      address:
        "Showroom No.3, Hamza Homes, Main Khalid Bin Walid Road, Maniya Society, Maniya CHS (MCHS)",
      phone: "03218959370",
      lat: 24.88048829233389, // Latitude for Hilal Motors
      lng: 67.06143115907345, // Longitude for Hilal Motors
      image: "images/dealers/3.webp",
      shedule: "Mon - Fri",
      timings: "08.00 - 18.00",
    },
    {
      name: "Haris Traders",
      address: "Shop No. 16A, Hashoo Center, Abdullah Haroon Road, Saddar",
      email: "mym786@hotmail.com",
      phone: "03002000066, 03008292542",
      lat: 24.863562105595605, // Latitude for Hilal Motors
      lng: 67.02416471239171, // Longitude for Hilal Motors
      image: "images/dealers/6.webp",
      shedule: "Mon - Sat",
      timings: "11.00 - 21.00",
    },

    // Add more dealers if needed
  ];
  return (
    <>
      <div id="wrapper">
        <div class="float-text show-on-scroll">
          <span>
            <a href="#">Scroll to top</a>
          </span>
        </div>
        <div class="scrollbar-v show-on-scroll"></div>

        {/* <!-- page preloader begin --> */}
        {/* <div id="de-loader"></div> */}
        {/* <!-- page preloader close --> */}

        <Header />

        {/* <!-- content begin --> */}
        <div class="no-bottom no-top" id="content">
          <div id="top"></div>

          <section class="pt10 pb10 mt80 bg-grey">
            <div class="container">
              <div class="row align-items-center">
                <div class="col-lg-6">
                  <h3 class="mb-0">
                    Dealers<i class="icofont-dotted-right id-color ms-2"></i>
                  </h3>
                </div>

                <div class="col-lg-6 text-lg-start">
                  <ul class="crumb">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li class="active">Dealers</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="container">
              <div className="row g-5">
                {dealers.map((dealer, index) => (
                  <div key={index} className="col-lg-4">
                    <div className="img-with-cap mb20">
                      <div className="d-title">
                        <span className="me-2"> {dealer.shedule}</span>
                        <span>{dealer.timings}</span>
                      </div>

                      <div className="d-overlay"></div>
                      <img
                        src={dealer.image}
                        className="img-fullwidth rounded-1"
                        alt={dealer.name}
                      />
                    </div>

                    <h4>{dealer.name}</h4>

                    <div className="fw-500">
                      <i className="icofont-location-pin me-2 id-color-2"></i>
                      {dealer.address}
                    </div>

                    <div className="fw-500">
                      {dealer.email && (
                        <>
                          <strong>Email: </strong> {dealer.email}
                        </>
                      )}
                    </div>

                    <div className="fw-500">
                      <strong>Phone: </strong> {dealer.phone}
                    </div>
                    {/* <div className="fw-500">
                      <i className="icofont-envelope me-2 id-color-2"></i>
                      {dealer.email}
                    </div> */}
                    {/* <div className="fw-500 mb-3">
                      <i className="icofont-phone me-2 id-color-2"></i>
                      {dealer.phone}
                    </div> */}
                    <div className="mt-2">
                      <a
                        href="#"
                        className="btn-text text-dark"
                        onClick={() =>
                          handleGetDirection(dealer.lat, dealer.lng)
                        }
                      >
                        Get Direction
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
        {/* <!-- content close --> */}

        <Footer />
      </div>
    </>
  );
};

export default Dealers;
