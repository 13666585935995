import React from 'react'

//Bike routes
const Bikes = React.lazy(() => import('../../screens/Bikes'))
const BikeForm = React.lazy(() => import('../../screens/Bikes/Form'))
const BikeDetails = React.lazy(() => import('../../screens/BikeDetails'))


//Purchase order routes
const PurchaseOrders = React.lazy(() => import('../../screens/PurchaseOrders'))
const PurchaseOrderForm = React.lazy(() => import('../../screens/PurchaseOrders/Form'))


//Dealers routes
const DealerView = React.lazy(() => import('../../screens/DealerView'))


const routes = [
    /////////////////////// Configuration Section ////////////////////

    //Bike routes
    { path: '/Evsystem/Bikes', name: 'Bikes', element: Bikes },
    { path: '/Evsystem/Bikes/New', name: 'Bikes', element: BikeForm },
    { path: '/Evsystem/Bikes/:id', name: 'Bikes', element: BikeForm },
    { path: '/Evsystem/BikeDetails', name: 'Bike Details', element: BikeDetails },


    //Purchase Order Routes
    { path: '/Evsystem/PurchaseOrders', name: 'Purchase Orders', element: PurchaseOrders },
    { path: '/Evsystem/PurchaseOrders/New', name: 'Purchase Orders', element: PurchaseOrderForm },
    { path: '/Evsystem/PurchaseOrders/:id', name: 'Purchase Orders', element: PurchaseOrderForm },


    //Dealer View Routes
    { path: '/Evsystem/Dealers', name: 'Dealers', element: DealerView },


]
export default routes
