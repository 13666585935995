import React from 'react'
import { Result } from 'antd';


const Page404 = () => {
  return (
    <div className="flex items-center justify-center h-screen" style={{ height: "100vh" }}>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
      />
    </div>
  )
}

export default Page404
