import React, { useEffect } from "react";
import { Link } from "react-router-dom";
const Header = () => {
  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src = `${process.env.PUBLIC_URL}/js/designesia.js`; // Use the correct path to your script
  //   script.defer = true;
  //   document.body.appendChild(script);

  //   // Cleanup function to remove the script when the component unmounts
  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);
  return (
    <>
      {/* <!-- header begin --> */}
      <header class="transparent">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="de-flex sm-pt10">
                <div class="de-flex-col">
                  {/* <!-- logo begin --> */}
                  <div id="logo">
                    <a href="/">
                      <img
                        class="logo-main"
                        src="images/ELFA-Logo-white.webp"
                        alt=""
                      />
                      <img
                        class="logo-scroll"
                        src="images/logo-black.webp"
                        alt=""
                      />
                      <img
                        class="logo-mobile"
                        src="images/ELFA-Logo-white.webp"
                        alt=""
                      />
                    </a>
                  </div>
                  {/* <!-- logo close --> */}
                </div>
                <div class="de-flex-col header-col-mid">
                  <ul id="mainmenu">
                    <li>
                      <Link className="menu-item" to="/">
                        Home
                      </Link>
                      {/* <a className="menu-item" href="/">
                        Home
                      </a> */}
                      {/* <ul class="mega">
                        <li>
                          <div class="container">
                            <div class="sb-menu p-4">
                              <div class="row g-4">
                                <div class="col-lg-2 text-center">
                                  <div class="bg-grey rounded-10px relative hover text-center">
                                    <a href="index.html">
                                      <img
                                        src="images/demo/homepage-1.jpg"
                                        class="w-100 relative z-1000 hover-scale-1-1 rounded-5px"
                                        alt=""
                                      />
                                    </a>

                                    <div class="absolute w-100 h-100 start-0 top-0 rounded-10px hover-bg-color"></div>
                                  </div>
                                  <h5 class="mt-3 mb-1">Homepage 1</h5>
                                </div>

                                <div class="col-lg-2 text-center">
                                  <div class="bg-grey rounded-10px relative hover text-center">
                                    <a href="homepage-2.html">
                                      <img
                                        src="images/demo/homepage-2.jpg"
                                        class="w-100 relative z-1000 hover-scale-1-1 rounded-5px"
                                        alt=""
                                      />
                                    </a>

                                    <div class="absolute w-100 h-100 start-0 top-0 rounded-10px hover-bg-color"></div>
                                  </div>
                                  <h5 class="mt-3 mb-1">Homepage 3</h5>
                                </div>

                                <div class="col-lg-2 text-center">
                                  <div class="bg-grey rounded-10px relative hover text-center">
                                    <a href="homepage-3.html">
                                      <img
                                        src="images/demo/homepage-3.jpg"
                                        class="w-100 relative z-1000 hover-scale-1-1 rounded-5px"
                                        alt=""
                                      />
                                    </a>

                                    <div class="absolute w-100 h-100 start-0 top-0 rounded-10px hover-bg-color"></div>
                                  </div>
                                  <h5 class="mt-3 mb-1">Homepage 3</h5>
                                </div>

                                <div class="col-lg-2 text-center">
                                  <div class="bg-grey rounded-10px relative hover text-center">
                                    <a href="homepage-4.html">
                                      <img
                                        src="images/demo/homepage-4.jpg"
                                        class="w-100 relative z-1000 hover-scale-1-1 rounded-5px"
                                        alt=""
                                      />
                                    </a>

                                    <div class="absolute w-100 h-100 start-0 top-0 rounded-10px hover-bg-color"></div>
                                  </div>
                                  <h5 class="mt-3 mb-1">Homepage 4</h5>
                                </div>

                                <div class="col-lg-2 text-center">
                                  <div class="bg-grey rounded-10px relative hover text-center">
                                    <a href="homepage-5.html">
                                      <img
                                        src="images/demo/homepage-5.jpg"
                                        class="w-100 relative z-1000 hover-scale-1-1 rounded-5px"
                                        alt=""
                                      />
                                    </a>

                                    <div class="absolute w-100 h-100 start-0 top-0 rounded-10px hover-bg-color"></div>
                                  </div>
                                  <h5 class="mt-3 mb-1">Homepage 5</h5>
                                </div>

                                <div class="col-lg-2 text-center">
                                  <div class="bg-grey rounded-10px relative hover text-center">
                                    <div>
                                      <img
                                        src="images/demo/coming-soon.jpg"
                                        class="w-100 relative z-1000 hover-scale-1-1 rounded-5px"
                                        alt=""
                                      />
                                    </div>

                                    <div class="absolute w-100 h-100 start-0 top-0 rounded-10px hover-bg-color"></div>
                                  </div>
                                  <h5 class="mt-3 mb-1">Coming Soon</h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul> */}
                    </li>
                    {/* Our Story */}
                    {/* <li>
                      <a class="menu-item" href="/about-us">
                        Our Story
                      </a>
                    </li> */}

                    <li>
                      <Link className="menu-item" to="/about-us">
                        Our Story
                      </Link>
                    </li>

                    {/* Bikes */}
                    <li>
                      <a class="menu-item">Bikes</a>
                      <ul>
                        {/* <li>
                          <a class="menu-item" href="/ev-1">
                            EV-1
                          </a>
                        </li> */}
                        <li>
                          {/* <a class="menu-item" href="/ev-125">
                            EV-125
                          </a> */}
                          <Link className="menu-item" to="/ev-125">
                            EV-125
                          </Link>
                        </li>
                        {/* <li>
                          <a class="menu-item" href="/ev-500">
                            EV-500
                          </a>
                        </li> */}
                      </ul>
                    </li>
                    {/* <li>
                      <a class="menu-item" href="vehicles.html">
                        Vehicles
                      </a>
                      <ul class="mega">
                        <li>
                          <div class="container">
                            <div class="sb-menu p-4">
                              <div class="row g-4">
                                <div class="col-lg-3 text-center">
                                  <div class="bg-grey p-2 rounded-10px relative hover text-center">
                                    <a href="vehicle-single-1.html">
                                      <img
                                        src="images/misc/4.webp"
                                        class="w-100 relative z-1000 hover-scale-1-2"
                                        alt=""
                                      />
                                      <img
                                        src="images/misc/10.webp"
                                        class="absolute start-10 top-10 w-20"
                                        alt=""
                                      />
                                    </a>

                                    <div class="absolute w-100 h-100 start-0 top-0 rounded-10px hover-bg-color"></div>
                                  </div>
                                  <h4 class="mt-3 mb-1">Autoev R100</h4>
                                  <p class="mb-0">
                                    Ride the Future: Silent, Smooth, Electric
                                  </p>
                                </div>
                                <div class="col-lg-3 text-center">
                                  <div class="bg-grey p-2 rounded-10px relative hover text-center">
                                    <a href="vehicle-single-2.html">
                                      <img
                                        src="images/misc/m2.webp"
                                        class="w-100 relative z-1000 hover-scale-1-2"
                                        alt=""
                                      />
                                      <img
                                        src="images/misc/10.webp"
                                        class="absolute start-10 top-10 w-20"
                                        alt=""
                                      />
                                    </a>

                                    <div class="absolute w-100 h-100 start-0 top-0 rounded-10px hover-bg-color"></div>
                                  </div>
                                  <h4 class="mt-3 mb-1">
                                    <span class="bg-color me-2 fs-15 px-2 rounded-5px text-uppercase anim-op-blink-to-light">
                                      New
                                    </span>
                                    Autoev R200
                                  </h4>
                                  <p class="mb-0">
                                    Redefining Motorcycle Performance.
                                  </p>
                                </div>
                                <div class="col-lg-3 text-center">
                                  <div class="bg-grey p-2 rounded-10px relative hover text-center">
                                    <a href="vehicle-single-3.html">
                                      <img
                                        src="images/misc/m3.webp"
                                        class="w-100 relative z-1000 hover-scale-1-2"
                                        alt=""
                                      />
                                      <img
                                        src="images/misc/10.webp"
                                        class="absolute start-10 top-10 w-20"
                                        alt=""
                                      />
                                    </a>

                                    <div class="absolute w-100 h-100 start-0 top-0 rounded-10px hover-bg-color"></div>
                                  </div>
                                  <h4 class="mt-3 mb-1">Autoev R300</h4>
                                  <p class="mb-0">
                                    Where Sustainability Meets Adventure.
                                  </p>
                                </div>

                                <div class="col-lg-3 text-center">
                                  <div class="bg-grey p-2 rounded-10px relative hover text-center">
                                    <a href="vehicles.html">
                                      <img
                                        src="images/misc/11.webp"
                                        class="w-100 relative z-1000 hover-scale-1-2"
                                        alt=""
                                      />
                                      <img
                                        src="images/misc/10.webp"
                                        class="absolute start-10 top-10 w-20"
                                        alt=""
                                      />
                                    </a>

                                    <div class="absolute w-100 h-100 start-0 top-0 rounded-10px hover-bg-color"></div>
                                  </div>
                                  <h4 class="mt-3 mb-1">All Vehicles</h4>
                                  <p class="mb-0">View All Models.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </li> */}

                    <li>
                      {/* <a class="menu-item" href="/dealers">
                        Dealers
                      </a> */}

                      <Link className="menu-item" to="/dealers">
                        Dealers
                      </Link>
                    </li>

                    <li>
                      {/* <a class="menu-item" href="/contact-us">
                        Contact Us
                      </a> */}
                      <Link className="menu-item" to="/referral">
                        Referral
                      </Link>
                    </li>
                    <li>
                      {/* <a class="menu-item" href="/contact-us">
                        Contact Us
                      </a> */}
                      <Link className="menu-item" to="/contact-us">
                        Contact Us
                      </Link>
                    </li>

                    <li class="bookatestdrive">
                      <Link className="menu-item" to="/book-a-test-drive">
                        Book A Test Ride
                      </Link>
                    </li>
                    {/* <li>
                      <a class="menu-item" href="#">
                        Pages
                      </a>
                      <ul class="mega mega-3-cols">
                        <div class="sb-menu p-4">
                          <div class="row g-4">
                            <div class="col-lg-4">
                              <h5>Company</h5>
                              <ul class="no-bg">
                                <li>
                                  <a href="about.html">About Us</a>
                                </li>
                                <li>
                                  <a href="news.html">News</a>
                                </li>
                                <li>
                                  <a href="testimonials.html">Testimonials</a>
                                </li>
                                <li>
                                  <a href="history.html">History</a>
                                </li>
                                <li>
                                  <a href="careers.html">Careers</a>
                                </li>
                              </ul>
                            </div>
                            <div class="col-lg-4">
                              <h5>Vehicles</h5>
                              <ul class="no-bg">
                                <li>
                                  <a href="vehicles.html">All Vehicles</a>
                                </li>
                                <li>
                                  <a href="vehicle-single-1.html">
                                    Vehicle Details 1
                                  </a>
                                </li>
                                <li>
                                  <a href="vehicle-single-2.html">
                                    Vehicle Details 2
                                  </a>
                                </li>
                                <li>
                                  <a href="vehicle-single-3.html">
                                    Vehicle Details 3
                                  </a>
                                </li>
                                <li>
                                  <a href="pricing-table.html">Pricing Table</a>
                                </li>
                              </ul>
                            </div>
                            <div class="col-lg-4">
                              <h5>Gallery</h5>
                              <ul class="no-bg">
                                <li>
                                  <a href="gallery-grid.html">Gallery Grid</a>
                                </li>
                                <li>
                                  <a href="gallery-carousel.html">
                                    Gallery Carousel
                                  </a>
                                </li>
                                <li>
                                  <a href="gallery-marquee.html">
                                    Gallery Marquee
                                  </a>
                                </li>
                              </ul>

                              <div class="spacer-single"></div>

                              <h5>Misc</h5>
                              <ul class="no-bg">
                                <li>
                                  <a href="bootstrap-5-elements.html">
                                    Bootstrap 5 Elements
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </ul>
                    </li> */}
                    {/* News Section */}
                    {/* <li>
                      <a class="menu-item" href="news.html">
                        News
                      </a>
                      <ul>
                        <li>
                          <a class="menu-item" href="news.html">
                            News Default
                          </a>
                        </li>
                        <li>
                          <a class="menu-item" href="news-modern.html">
                            News Modern
                          </a>
                        </li>
                        <li>
                          <a class="menu-item" href="news-creative.html">
                            News Creative
                          </a>
                        </li>
                        <li>
                          <a class="menu-item" href="news-poster.html">
                            News Poster
                          </a>
                        </li>
                        <li>
                          <a class="menu-item" href="news-classic.html">
                            News Classic
                          </a>
                        </li>
                        <li>
                          <a class="menu-item" href="news-plain.html">
                            News Plain
                          </a>
                        </li>
                        <li>
                          <a class="menu-item" href="news-poster-fullpage.html">
                            News Fullpage
                          </a>
                        </li>
                        <li>
                          <a class="menu-item" href="news-sidebar-1.html">
                            News with Sidebar
                          </a>
                        </li>
                      </ul>
                    </li> */}

                    {/* Contact Section */}
                    {/* <li>
                      <a class="menu-item" href="contact.html">
                        Contact
                      </a>
                    </li> */}
                  </ul>
                </div>
                <div class="de-flex-col">
                  <div class="menu_side_area ">
                    <a
                      href="/book-a-test-drive"
                      class="btn-line test-drive-btn-outer"
                    >
                      Book a Test Ride
                    </a>
                    <span id="menu-btn"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <!-- header close --> */}
    </>
  );
};

export default Header;
