import React from "react";
import { useState, useEffect } from "react";
import Header from "../components/Navbar/Header";
import Footer from "../components/Footer/Footer";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import { logEvent, trackMetaEvent } from "../analytics/analytics";
const { PHONE_NUMBER, EMAIL, LOCATION, APP_URL, WHATSAPP_NUMBER } =
  window["appconfig"];

const MySwal = withReactContent(Swal);

const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Handle form input changes
  const handleNameChange = (e) => setName(e.target.value);
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePhoneChange = (e) => setPhone(e.target.value);
  const handleMessageChange = (e) => setMessage(e.target.value);

  const formattedWhatsAppNumber = WHATSAPP_NUMBER.replace(/\D/g, "");
  //const formattedPhoneNumber = PHONE_NUMBER.replace(/\D/g, "");

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `${process.env.PUBLIC_URL}/js/designesia.js`; // Use the correct path to your script
    script.defer = true;
    document.body.appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleGetDirection = (lat, lng) => {
    const destination = `${lat},${lng}`;
    window.open(
      `https://www.google.com/maps/dir/?api=1&destination=${destination}`,
      "_blank"
    );
  };

  // post request using fetch
  // const handleSubmit = async (e) => {
  //   e.preventDefault(); // Prevent page reload

  //   console.log("Button clicked");
  //   // Create form data object
  //   const formData = {
  //     name,
  //     phone,
  //     email,
  //     message,
  //   };

  //   console.log(formData, "Form data");

  //   // // Example API call (replace with your actual logic)
  //   try {
  //     const response = await fetch("http://localhost:3000/api/submit-form", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(formData),
  //     });

  //     if (response.ok) {
  //       setSuccessMessage("Your message has been sent successfully.");
  //       setErrorMessage("");
  //       setName("");
  //       setEmail("");
  //       setPhone("");
  //       setMessage("");
  //     } else {
  //       setErrorMessage("Sorry, there was an error sending your form.");
  //       setSuccessMessage("");
  //     }
  //   } catch (error) {
  //     setErrorMessage("Sorry, there was an error sending your form.");
  //     setSuccessMessage("");
  //   }
  // };

  // Post request using axios
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent page reload

    console.log("Button clicked");
    // Log the event with correct parameters
    logEvent("contact_form_submit", {
      form_id: "contact_form",
      form_name: "contactForm",
      form_destination: window.location.href,
      form_length: 5, // Adjust as needed
      form_submit_text: "Send Message",
    });

    trackMetaEvent("ContactFormSubmit", {
      form_id: "contact_form",
      form_name: "contactForm",
      form_destination: window.location.href,
      form_length: 5, // Adjust as needed
      form_submit_text: "Send Message",
    });
    setIsLoading(true);

    // Create form data object
    const formData = {
      name,
      phone,
      email,
      message,
    };

    console.log(formData, "Form data");

    // Axios POST request
    try {
      const response = await axios.post(`${APP_URL}/api/submit-form`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

   

      console.log(response, "axios post request response----");

      if (response.status === 200) {
        // response.ok is equivalent to checking for status code 200 in axios
        setSuccessMessage("Your message has been sent successfully.");
        // toast.success("Message sent successfully!");
        MySwal.fire({
          title: "Success!",
          text: "Thanks! We're gearing up and will be in touch soon.",
          icon: "success",
          confirmButtonText: "OK",
        });
        setErrorMessage("");
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
        setIsLoading(false);
      } else {
        console.log("erorr if else");
        setErrorMessage("Sorry, there was an error sending your form.");
        // toast.error("Sorry, there was an error sending your form.");
        MySwal.fire({
          title: "Error!",
          text: "Sorry, there was an error sending your form.",
          icon: "error",
          confirmButtonText: "OK",
        });
        setSuccessMessage("");
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error, "erorr catch");
      setErrorMessage("Sorry, there was an error sending your form.");
      MySwal.fire({
        title: "Error!",
        text: "Sorry, there was an error sending your form.",
        icon: "error",
        confirmButtonText: "OK",
      });
      // toast.error("Failed to send message. Please check your connection.");
      setSuccessMessage("");
      setIsLoading(false);
    }
  };
  return (
    <>
      <div id="wrapper">
        <div class="float-text show-on-scroll">
          <span>
            <a href="#">Scroll to top</a>
          </span>
        </div>
        <div class="scrollbar-v show-on-scroll"></div>
        {/* <!-- page preloader begin --> */}
        {/* <div id="de-loader"></div> */}
        {/* <!-- page preloader close --> */}

        {/* <!-- header begin --> */}
        <Header />
        {/* <!-- header close --> */}

        {/* <!-- content begin --> */}
        <div class="no-bottom no-top" id="content">
          <div id="top"></div>
          <section class="pt10 pb10 mt80 bg-grey">
            <div class="container">
              <div class="row align-items-center">
                <div class="col-lg-6">
                  <h3 class="mb-0">
                    Contact<i class="icofont-dotted-right id-color ms-2"></i>
                  </h3>
                </div>

                <div class="col-lg-6 text-lg-start">
                  <ul class="crumb">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li class="active">Contact Us</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div class="container">
              <div class="row g-4 gx-5">
                <div class="col-lg-8">
                  <p>
                    Whether you have a question, a suggestion, or just want to
                    say hello, this is the place to do it. Please fill out the
                    form below with your details and message, and we'll get back
                    to you as soon as possible.
                  </p>

                  <form
                    name="contactForm"
                    id="contact_form"
                    class="position-relative z1000"
                    method="post"
                    action="#"
                    onSubmit={handleSubmit}
                  >
                    <div class="row gx-4">
                      <div class="col-lg-6 col-md-6 mb10">
                        <div class="field-set">
                          <span class="d-label fw-bold">Name</span>
                          <input
                            type="text"
                            name="Name"
                            id="name"
                            class="form-control"
                            placeholder="Your Name"
                            required
                            value={name}
                            onChange={handleNameChange}
                          />
                        </div>

                        <div class="field-set">
                          <span class="d-label fw-bold">Email</span>
                          <input
                            type="email"
                            name="Email"
                            id="email"
                            class="form-control"
                            placeholder="Your Email"
                            value={email}
                            onChange={handleEmailChange}
                            required
                          />
                        </div>

                        <div class="field-set">
                          <span class="d-label fw-bold">Phone</span>
                          <input
                            type="tel"
                            name="phone"
                            id="phone"
                            class="form-control"
                            placeholder="Your Phone"
                            value={phone}
                            onChange={handlePhoneChange}
                            maxLength="13"
                            required
                          />
                        </div>
                      </div>

                      <div class="col-lg-6 col-md-6">
                        <div class="field-set mb20">
                          <span class="d-label fw-bold">Message</span>
                          <textarea
                            name="message"
                            id="message"
                            class="form-control"
                            placeholder="Your Message"
                            value={message}
                            onChange={handleMessageChange}
                            required
                          ></textarea>
                        </div>
                      </div>
                    </div>

                    <div
                      class="g-recaptcha"
                      data-sitekey="copy-your-sitekey-here"
                    ></div>
                    <div id="submit" class="mt20">
                      <input
                        type="submit"
                        id="send_message"
                        value={isLoading ? "Loading..." : "Send Message"}
                        class="btn-main"
                        disabled={isLoading}
                      />
                    </div>

                    {successMessage && (
                      <div id="success_message" className="success">
                        {successMessage}
                      </div>
                    )}
                    {errorMessage.length > 0 && (
                      <div id="error_message" className="error">
                        {errorMessage}
                      </div>
                    )}
                  </form>
                </div>

                <div class="col-lg-4">
                  <h4>Our Office</h4>
                  <div class="img-with-cap mb20">
                    <div class="d-title">Mon - Fri 10.00am - 6.00pm</div>
                    <div class="d-overlay"></div>
                    <img
                      src="images/misc/office.webp"
                      class="img-fullwidth rounded-1"
                      alt=""
                    />
                  </div>
                  <div class="spacer-single"></div>
                  <div class="fw-bold text-dark">
                    <i class="icofont-location-pin me-2 id-color-2"></i>Office
                    Location
                  </div>
                  <a
                    href="#"
                    onClick={() => handleGetDirection(24.799889, 67.10096)}
                  >
                    {LOCATION}
                  </a>

                  <div class="spacer-single"></div>
                  <div class="fw-bold text-dark">
                    <i class="icofont-whatsapp me-2 id-color-2"></i>WhatsApp
                    Support
                  </div>
                  <a
                    href={`https://wa.me/${formattedWhatsAppNumber}`}
                    target="_blank"
                  >
                    {WHATSAPP_NUMBER}
                  </a>

                  <div class="spacer-single"></div>
                  <div class="fw-bold text-dark">
                    <i class="icofont-envelope me-2 id-color-2"></i>Send a
                    Message
                  </div>
                  <a href="mailto:info@elfaelectric.com">{EMAIL}</a>
                  {/* <div class="spacer-single"></div>
                  <div class="fw-bold text-dark">
                    <i class="icofont-phone me-2 id-color-2"></i>Call Us
                    Directly
                  </div>
                  <a href={`tel:+${formattedPhoneNumber}`}>{PHONE_NUMBER}</a>
                  <div class="spacer-single"></div> */}
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* <!-- content close --> */}
        <Footer />
      </div>
    </>
  );
};

export default ContactUs;
