
import axios from "axios";
import MySwal from "sweetalert2";
import { wtGetFromLocalStorage } from "./LocalStorage";

export const callApi = async ({ url, method = "GET", body = {}, responseType = "json", headers = {} }) => {
    const token = wtGetFromLocalStorage("elfa_token", "");

    const defaultHeaders = {
        "Content-Type": "application/json",
        ...(token && { Authorization: `Bearer ${token}` }), // Add Authorization header only if token exists
    };

    try {
        const config = {
            url,
            method,
            headers: { ...defaultHeaders, ...headers }, 
            responseType,
        };

        // Use 'params' for GET requests and 'data' for others
        if (method.toUpperCase() === "GET") {
            config.params = body;
        } else {
            config.data = body;
        }

        const response = await axios(config);

        // Success: HTTP status code 2xx
        if (response.status >= 200 && response.status < 300) {
            return { data: response.data, error: null };
        }
    } catch (error) {
        const status = error.response?.status;

        // Handle 401 Unauthorized
        if (status === 401) {
            MySwal.fire({
                title: "Session Expired",
                text: "Your session has expired. Please log in again.",
                icon: "warning",
                confirmButtonText: "OK",
            });
            doLogout(); // Redirect or clear session
        } else {
            // Show error message for other response status codes
            MySwal.fire({
                title: "Error!",
                text: error.response?.data?.message || "An unexpected error occurred. Please try again.",
                icon: "error",
                confirmButtonText: "OK",
            });
        }

        console.error("API call error:", error); // Log the error for debugging
        return { data: null, error };
    }
};




export const doLogout = () => {

    localStorage.setItem("elfa_token", "");
    localStorage.setItem("elfa_user_data", null);

    // Invalidate the display
    window.location.reload();
};

