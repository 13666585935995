import React, { useEffect } from "react";
import Navbar from "../components/Navbar/Header";
import Footer from "../components/Footer/Footer";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Autoplay } from "swiper/modules";
// import { Navigation, Pagination, Scrollbar } from "swiper/modules";
// import Carousel from "react-multi-carousel";
// import "swiper/swiper-bundle.css";

const Referral = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `${process.env.PUBLIC_URL}/js/designesia.js`; // Use the correct path to your script
    script.defer = true;
    document.body.appendChild(script);
    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <div id="wrapper">
        <div class="float-text show-on-scroll">
          <span>
            <a href="#">Scroll to top</a>
          </span>
        </div>
        <div class="scrollbar-v show-on-scroll"></div>

        {/* <!-- page preloader begin --> */}
        {/* <div id="de-loader"></div> */}
        {/* <!-- page preloader close --> */}

        <Navbar />

        {/* <!-- content begin --> */}

        {/* <!-- content begin --> */}
        <div class="no-bottom no-top" id="content">
          <div id="top"></div>

          <div className="container-fluid p-0 mt-5">
            <div className="banner-container mt-5">
              <img
                src="images/background/referral.png"
                alt=""
                className="img-fluid w-100 mt-4"
              />
            </div>

            <div className="content-container mt-5">
              <div className="container">
                <h2 className="text-center mb-4">
                  Exciting News from ELFA Electric!
                </h2>
                <p className="lead text-center">
                  We’re excited to launch our <b>Referral Program,</b> open to
                  everyone!
                </p>
                <h4>Here’s how it works:</h4>
                <ul>
                  <li>Refer a lead to ELFA Electric.</li>
                  <li>
                    If they purchase the ELFA EV-125, you’ll earn a{" "}
                    <b>PKR 10,000 referral bonus</b> for every bike sold!
                  </li>
                </ul>
                <p className="mb-4">It’s that simple!</p>
                <h4>Got a lead?</h4>
                <p>
                  Message us, call us on WhatsApp at{" "}
                  <a href="https://wa.me/923114863532" target="_blank">
                    +92 3114863532
                  </a>
                  , or email us at{" "}
                  <a href="mailto:info@elfaelectric.com">
                    info@elfaelectric.com
                  </a>
                  , share your Lead with us, and get a code from our team.
                </p>
                <p className="mb-4">
                  <b>Have questions?</b> We’re here to help!
                </p>
                <p>Let’s drive towards a greener future together. 🌍⚡</p>
                <h4 className="text-left">Ready, set, REFER! 🚀</h4>
                <hr />
                <h4>Terms and conditions:</h4>
                <h5>1. General Rules</h5>
                <ul>
                  <li>
                    The referral program is applicable only for the purchase of
                    new bikes.
                  </li>
                  <li>
                    Any individual (not limited to employees or customers) can
                    participate as a referrer.
                  </li>
                  <li>
                    Bike delivery date will be confirmed based on availability.
                  </li>
                  <li>Bookings will be possible for a later date.</li>
                </ul>
                <h5>2. Referrer Rewards</h5>
                <ul>
                  <li>
                    The referrer will earn PKR 10,000 for every successful
                    referral.
                  </li>
                  <li>
                    The referrer can get their unique referral code through ELFA
                    WhatsApp.
                  </li>
                  <li>
                    A referral is considered successful if the referee completes
                    the purchase of a bike.
                  </li>
                  <li>
                    The referrer will receive the reward within 15 business days
                    after the referee's purchase is confirmed and the amount is
                    received.
                  </li>
                  <li>
                    Rewards will be issued as cheque, or via bank transfer (as
                    chosen by the referrer).
                  </li>
                  <li>
                    There is no limit to the number of referrals a referrer can
                    make.
                  </li>
                </ul>
                <h5>3. Eligibility Criteria</h5>
                <ul>
                  <li>
                    The referrer must have a valid referral code, which can be
                    generated via ELFA WhatsApp.
                  </li>
                  <li>The referee is allowed to buy multiple bikes.</li>
                  <li>
                    Employees of Wavetec can participate as referrers but cannot
                    refer to themselves.
                  </li>
                </ul>

                <h5>4. Program Terms</h5>
                <ul>
                  <li>
                    The referral program cannot be combined with other
                    promotional offers or discounts.
                  </li>
                  <li>
                    If multiple referrers refer to the same person, the reward
                    will be given to the referrer whose code was used first.
                  </li>
                  <li>
                    EV Tech Global reserves the right to verify all transactions
                    and reject any fraudulent or ineligible referrals.
                  </li>
                  <li>
                    The program terms and conditions are subject to change at
                    the sole discretion of EV TechGlobal.
                  </li>
                </ul>
                <h5>5. Duration and Validity</h5>
                <ul>
                  <li>The referral program is valid until further notice.</li>
                </ul>

                <a
                  class="btn-main wow fadeIn mb-5 "
                  data-wow-delay=".3s"
                  href="/contact-us"
                >
                  Contact Us
                </a>

                <br />
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default Referral;
