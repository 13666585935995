import React, { useEffect, useState } from 'react';
import AppContent from './AppContent';
import AppSidebar from './AppSidebar';
import AppFooter from './AppFooter';
import AppHeader from './AppHeader';
import { Layout, theme } from 'antd';
import { callApi, doLogout } from '../helpers/commons/ApiWrapper';
import { wtGetFromLocalStorage, wtSaveToLocalStorage } from '../helpers/commons/LocalStorage';

const { APP_URL } = window["appconfig"];

const DefaultLayout = () => {
    const [collapsed, setCollapsed] = useState(false);

    useEffect(() => {
        const fetchUserData = async () => {
            const authToken = wtGetFromLocalStorage('elfa_token', '');
            if (authToken) {
                try {
                    // Call API to load logged-in user data
                    const { data, error } = await callApi({
                        url: `${APP_URL}/api/logged_in_user`,
                        method: "GET",
                    });

                    if (error) {
                        // Handle error and logout
                        doLogout();
                    } else {
                        const { token, userData } = data.data;

                        // Save JWT token and user data to local storage
                        wtSaveToLocalStorage("elfa_token", token);
                        wtSaveToLocalStorage("elfa_user_data", userData);
                    }
                } catch (err) {
                    // Handle any unexpected errors and logout
                    console.error("Error fetching user data:", err);
                    doLogout();
                }
            } else {
                doLogout();
            }
        };
        // Call the async function
        fetchUserData();
    }, []);


    const {
        token: { colorBgContainer },
    } = theme.useToken();

    return (
        <Layout style={{ minHeight: '100vh' }}>
            {/* Sidebar */}
            <AppSidebar collapsed={collapsed} setCollapsed={setCollapsed} />

            {/* Main Layout */}
            <Layout>
                {/* Header */}
                <AppHeader />

                {/* Content */}
                <AppContent colorBgContainer={colorBgContainer} />

                {/* Footer */}
                <AppFooter />
            </Layout>
        </Layout>
    );
};

export default DefaultLayout;
