import React, { useEffect, useState } from 'react'
import { navAdmin } from './_nav-admin'
import { navSuper } from './_nav-super'
import { Layout, Menu } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { wtGetFromLocalStorage } from "../helpers/commons/LocalStorage";
import { navDealer } from './_nav-dealers';

const { Sider } = Layout;

const AppSidebar = ({ collapsed, setCollapsed }) => {
  const authUser = wtGetFromLocalStorage("elfa_user_data", null);
  const navigate = useNavigate()

  const menu = (+authUser.role === 1) ? navSuper : navDealer;
  const items = menu;
  const [selectedKey, setSelectedKey] = useState('');
  const location = useLocation();

  // Function to handle screen size change
  const handleResize = () => {
    const isMobile = window.innerWidth <= 768; // Define mobile breakpoint
    setCollapsed(isMobile); // Collapse sidebar if on mobile
  };

  useEffect(() => {
    // Initialize sidebar state based on screen size
    handleResize();

    // Add event listener to handle resize events
    window.addEventListener('resize', handleResize);

    // Cleanup event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {
    // Find the matching menu item based on the current location
    const splittedPath = location.pathname.split('/');
    const currentPath = '/' + splittedPath[1] + '/' + splittedPath[2];
    const matchingItem = menu.find(item => item.to === currentPath);
    // Set the selected key if a matching item is found
    if (matchingItem) {
      setSelectedKey(matchingItem.key);
    } else {
      // Set default selected key if no matching item is found
      setSelectedKey(menu[0].key);
    }
  }, [location.pathname, menu]);

  const onClick = (e) => {
    const navItem = items.find(item => item.key === e.key);
    if (typeof navItem !== 'undefined') {
      navigate(navItem.to)
    }
  };


  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={setCollapsed}
      style={{ backgroundColor: '#001529' }}
    >
      <Menu
        theme="dark"
        mode="inline"
        style={{ marginTop: 80 }}
        selectedKeys={[selectedKey]}
        items={menu.map((item) => ({
          key: item.key,
          label: item.label,
          icon: item.icon,
        }))}
        onClick={onClick}
      />
    </Sider>
  )
}

export default React.memo(AppSidebar)
