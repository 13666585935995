import React, { useEffect } from "react";
import Footer from "../components/Footer/Footer";
import Header from "../components/Navbar/Header";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `${process.env.PUBLIC_URL}/js/designesia.js`; // Use the correct path to your script
    script.defer = true;
    document.body.appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <>
      <div id="wrapper">
        <div class="float-text show-on-scroll">
          <span>
            <a href="#">Scroll to top</a>
          </span>
        </div>
        <div class="scrollbar-v show-on-scroll"></div>

        {/* <!-- page preloader begin --> */}
        {/* <div id="de-loader"></div> */}
        {/* <!-- page preloader close --> */}

        <Header />

        {/* <!-- content begin --> */}
        <div class="no-bottom no-top" id="content">
          <div id="top"></div>

          <section class="pt10 pb10 mt80 bg-grey">
            <div class="container">
              <div class="row align-items-center">
                <div class="col-lg-6">
                  <h3 class="mb-0">
                    Privacy<i class="icofont-dotted-right id-color ms-2"></i>
                  </h3>
                </div>

                <div class="col-lg-6 text-lg-start">
                  <ul class="crumb">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li class="active">Privacy Policy</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div>
              <div class="container">
                <h1 className="text-center">Privacy Policy</h1>
                <div class="row">
                  <div class="col-md-12">
                    <p>
                      ELFA respects your privacy. This policy outlines our
                      practices regarding the collection, use, and protection of
                      personal information on our website.
                    </p>
                  </div>
                </div>
              </div>

              <div class="container">
                <h3 className="">Information Collection & Use</h3>
                <div class="row">
                  <div class="col-md-12">
                    <p>
                      ELFA is the exclusive owner of information collected on
                      this site. We collect information at various points,
                      strictly for contacting users and improving our services.
                      We do not sell, share, or rent this information to others
                      outside the disclosed practices.
                    </p>
                  </div>
                </div>
              </div>

              <div class="container">
                <h3 className="">Cookies</h3>
                <div class="row">
                  <div class="col-md-12">
                    <p>
                      We use cookies to enhance your experience, enabling you to
                      avoid repeated logins and to access full functionality.
                      You can disable cookies through your browser settings,
                      though some features may be affected.
                    </p>
                  </div>
                </div>
              </div>

              <div class="container">
                <h3 className="">Data Sharing</h3>
                <div class="row">
                  <div class="col-md-12">
                    <p>
                      Aggregate demographic information may be shared with
                      partners to secure funding for continued service. This
                      data is anonymous and does not identify any individual.
                    </p>
                  </div>
                </div>
              </div>

              <div class="container">
                <h3 className="">Links to Other Sites </h3>
                <div class="row">
                  <div class="col-md-12">
                    <p>
                      Our website contains links to other websites. We are not
                      responsible for the privacy practices of other sites and
                      encourage you to read their privacy statements.
                    </p>
                  </div>
                </div>
              </div>

              <div class="container">
                <h3 className="">Security </h3>
                <div class="row">
                  <div class="col-md-12">
                    <p>
                      We take precautions to protect your information with
                      appropriate security measures.
                    </p>
                  </div>
                </div>
              </div>

              <div class="container">
                <h3 className="">User Surveys </h3>
                <div class="row">
                  <div class="col-md-12">
                    <p>
                      Occasionally, we may request information via surveys.
                      Participation is voluntary and used to improve our
                      services.
                    </p>
                  </div>
                </div>
              </div>

              <div class="container">
                <h3 className="">Data Correction/Updating </h3>
                <div class="row">
                  <div class="col-md-12">
                    <p>
                      You can update or remove your personal information via
                      your account settings on our site.
                    </p>
                  </div>
                </div>
              </div>

              <div class="container">
                <h3 className="">Choice/Opt-In </h3>
                <div class="row">
                  <div class="col-md-12">
                    <p>
                      You can opt in to receive newsletters and updates from us
                      and can unsubscribe at any time.
                    </p>
                  </div>
                </div>
              </div>

              <div class="container">
                <h3 className="">Policy Updates</h3>
                <div class="row">
                  <div class="col-md-12">
                    <p>
                      We reserve the right to update this policy and will post
                      changes on our site. You will be notified of significant
                      changes via email.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* <!-- content close --> */}
        <Footer />
      </div>
    </>
  );
};

export default PrivacyPolicy;
