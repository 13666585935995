import React from 'react'
import { useLocation } from 'react-router-dom'

import routes from '../routes'

import { Breadcrumb } from 'antd';

const AppBreadcrumb = () => {
  const currentLocation = useLocation().pathname

  const getRouteName = (pathname, routes) => {
    const currentRoute = routes.find((route) => route.path === pathname)
    return currentRoute ? currentRoute.name : false
  }

  const getBreadcrumbs = (location) => {
    const breadcrumbs = [{ href: "/", title: 'Home', active: false }]

    location.split('/').reduce((prev, curr, index, array) => {
      if (curr == 'New' || curr == 'new') {
        return;
      }
      const currentPathname = `${prev}/${curr}`
      const routeName = getRouteName(currentPathname, routes)
      routeName &&
        breadcrumbs.push({
          href: currentPathname,
          title: routeName,
          active: index + 1 === array.length ? true : false,
        })
      return currentPathname
    })
    return breadcrumbs
  }

  const breadcrumbs = getBreadcrumbs(currentLocation)

  return (

    <Breadcrumb
      style={{ margin: '0 16px',color:"black" }}
      items={breadcrumbs}
    />
  )
}

export default React.memo(AppBreadcrumb)
