export const ROLE_SUPER = 1;
export const ROLE_DEALER = 2;

export const ROLE_NAMES = {
    1: 'Super User',
    2: 'Dealer',
};
export const ROLE_DROPDOWN_OPTIONS = [
    { value: ROLE_SUPER, label: ROLE_SUPER[ROLE_SUPER] },
    { value: ROLE_DEALER, label: ROLE_DEALER[ROLE_DEALER] },
]