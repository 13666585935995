import React, { useEffect } from "react";
import { Link } from "react-router-dom";
const {
  PHONE_NUMBER,
  EMAIL,
  LOCATION,
  FOOTER_CONTENT,
  FACEBOOK_LINK,
  INSTAGRAM_LINK,
  LINKEDIN_LINK,
  WHATSAPP_NUMBER,
} = window["appconfig"];

const Footer = () => {
  console.log(FOOTER_CONTENT[0].COPYRIGHT_CONTENT, "FOOTER");
  const formattedWhatsAppNumber = WHATSAPP_NUMBER.replace(/\D/g, "");
  //const formattedPhoneNumber = PHONE_NUMBER.replace(/\D/g, "");

  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src = `${process.env.PUBLIC_URL}/js/designesia.js`; // Use the correct path to your script
  //   script.defer = true;
  //   document.body.appendChild(script);

  //   // Cleanup function to remove the script when the component unmounts
  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);
  return (
    <>
      {/* <!-- footer begin --> */}
      <footer class="section-dark">
        <div class="container">
          <div class="row gx-5">
            <div class="col-lg-4 col-sm-6">
              <img src="images/ELFA-Logo-white.webp" alt="" />
              <div class="spacer-20"></div>
              <p>
                Transforming perceptions of motorbikes and their environmental
                impact, for a greener tomorrow.
              </p>

              <div class="social-icons mb-sm-30">
                <a href={FACEBOOK_LINK} target="blank">
                  <i class="fa-brands fa-facebook-f"></i>
                </a>
                <a href={INSTAGRAM_LINK} target="blank">
                  <i class="fa-brands fa-instagram"></i>
                </a>
                <a href={LINKEDIN_LINK} target="blank">
                  <i class="fa-brands fa-linkedin"></i>
                </a>
                {/* <a href="#">
                  <i class="fa-brands fa-youtube"></i>
                </a>
                <a href="#">
                  <i class="fa-brands fa-tiktok"></i>
                </a> */}
              </div>
            </div>
            <div class="col-lg-4 col-sm-12 order-lg-1 order-sm-2">
              <div class="row">
                <div class="col-lg-6 col-sm-6">
                  <div class="widget">
                    <h5>Company</h5>
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/about-us">Our Story</Link>
                      </li>
                      {/* <li>
                        <a href="testimonials.html">Testimonials</a>
                      </li> */}
                      {/* <li>
                        <a href="careers.html">Careers</a>
                      </li> */}
                      <li>
                        <Link to="/contact-us">Contact Us</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="widget">
                    <h5>Our Products</h5>
                    <ul>
                      {/* <li>
                        <a href="/ev-1">EV-1</a>
                      </li> */}
                      <li>
                        <Link to="/ev-125">EV-125</Link>
                      </li>
                      {/* <li>
                        <a href="/ev-500">EV-500</a>
                      </li> */}
                      <li>
                        {/* <a
                        // href="vehicles.html"
                        >
                          All Vehicles
                        </a> */}
                      </li>
                      <li>
                        {/* <a
                        // href="dealers.html"
                        >
                          Dealers
                        </a> */}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6 order-lg-2 order-sm-1">
              <div class="widget">
                <div class="fw-bold text-white">
                  <i class="icofont-location-pin me-2 id-color"></i>
                  Office Location
                </div>
                {LOCATION}
                <div class="spacer-20"></div>
                <div class="fw-bold text-white">
                  <i class="icofont-whatsapp me-2 id-color"></i>WhatsApp Support
                </div>
                <a
                  href={`https://wa.me/${formattedWhatsAppNumber}`}
                  target="_blank"
                >
                  {WHATSAPP_NUMBER}
                </a>
                <div class="spacer-20"></div>
                <div class="fw-bold text-white">
                  <i class="icofont-envelope me-2 id-color"></i>Email Us
                </div>
                <a href="mailto:info@elfaelectric.com">{EMAIL}</a>
                <div class="spacer-20"></div>
                {/* <div class="fw-bold text-white">
                  <i class="icofont-phone me-2 id-color"></i>Our Helpline
                </div>
                <a href={`tel:+${formattedPhoneNumber}`} className="text-white">
                  {PHONE_NUMBER}
                </a> */}
                {/* {PHONE_NUMBER} */}
              </div>
            </div>
          </div>
        </div>
        <div class="subfooter">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="de-flex">
                  <div class="de-flex-col">
                    {FOOTER_CONTENT[0].COPYRIGHT_CONTENT}
                  </div>
                  <ul class="menu-simple">
                    {/* <li>
                      <a href="#">Terms &amp; Conditions</a>
                    </li> */}
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/faqs">FAQs</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* <!-- footer close --> */}
    </>
  );
};

export default Footer;
