import {
    UserOutlined,
    CloudUploadOutlined,
    MessageOutlined,
    ShoppingCartOutlined,
    ShoppingOutlined
} from '@ant-design/icons';


export const navSuper = [
    {
        key: '1',
        icon: <ShoppingOutlined />,
        label: 'Bikes',
        to: '/Evsystem/Bikes',
    },
    {
        key: '2',
        icon: <CloudUploadOutlined />,
        label: 'Bike Details',
        to: '/Evsystem/BikeDetails',
    },
    {
        key: '3',
        icon: <ShoppingCartOutlined />,
        label: 'Purchase Orders',
        to: '/Evsystem/PurchaseOrders',
    },
];