// LoginPage.js
import React, { useState } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import withReactContent from "sweetalert2-react-content";
import { wtSaveToLocalStorage } from "../helpers/commons/LocalStorage";
import { ROLE_DEALER, ROLE_SUPER } from "../Models/UserModel";

const { APP_URL } = window["appconfig"];

const MySwal = withReactContent(Swal);

function Login() {
  // State to store form input values
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate(); // Initialize useNavigate

  // Handle input changes for both fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent page reload
    setIsLoading(true);

    // Collect form data for login
    const loginData = {
      email_address: formData.email, // assuming 'email' is stored in the component state
      password: formData.password, // assuming 'password' is stored in the component state
    };

    try {
      // Make POST request to login endpoint
      let response = await axios.post(`${APP_URL}/api/login`, loginData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        // SweetAlert for success notification
        MySwal.fire({
          title: "Success!",
          text: "Login successful!",
          icon: "success",
          confirmButtonText: "OK",
        });

        // Optional: Save token to localStorage or state
        const { token, userData } = response.data.data;

        // Save JWT token in local storage
        wtSaveToLocalStorage("elfa_token", token);

        //Save user is local storage
        wtSaveToLocalStorage("elfa_user_data", userData);

        // Reset states and form
        setFormData({ email: "", password: "" });
        setIsLoading(false);

        if (+userData.role === ROLE_DEALER) {
          navigate("/Evsystem/Dealers");
        }
        else {
          navigate("/Evsystem/BikeDetails");
        }

      } else {
        // SweetAlert for error notification
        MySwal.fire({
          title: "Error!",
          text: "Invalid email or password.",
          icon: "error",
          confirmButtonText: "OK",
        });

        setIsLoading(false);
      }
    } catch (error) {

      MySwal.fire({
        title: "Error!",
        text: "Invalid Email or Password.",
        icon: "error",
        confirmButtonText: "OK",
      });
      setIsLoading(false);
    }
  };

  return (
    <Container
      fluid
      className="d-flex align-items-center justify-content-center vh-100 bg-light"
    >
      <Row className="justify-content-center w-100">
        <Col xs={10} sm={8} md={6} lg={4}>
          <Card className="shadow">
            <Card.Body>
              <h2 className="text-center mb-4">Login</h2>
              <Form onSubmit={handleLogin}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Enter your email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    placeholder="Enter your password"
                    value={formData.password}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>

                <Button
                  variant="success"
                  className="fixed-size-btn"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? "Loading..." : "Login"}
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
