import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../screens/Home";
import ContactUs from "../screens/ContactUs";
import OurStory from "../screens/OurStory";
import EV1 from "../screens/EV1";
import EV125 from "../screens/EV125";
import EV500 from "../screens/EV500";
import PrivacyPolicy from "../screens/PrivacyPolicy";
import FAQ from "../screens/FAQ";
import Dealers from "../screens/Dealers";
import TestDrive from "../screens/TestDrive";
import DefaultLayout from "../screens/DefaultLayout";
import Referral from "../screens/Referral";

// Auth routes
import Login from "../screens/Auth/Login";

// Erp Routes
import Page404 from "../screens/DefaultLayout/Page404";
import { wtGetFromLocalStorage } from "../screens/helpers/commons/LocalStorage";

function Router() {
  const AuthenticatedRoutes = ({ children }) => {
    const authToken = wtGetFromLocalStorage("elfa_token", "");
    return authToken ? children : <Navigate to="/Evsystem/login" />;
  };

  const CheckLogin = () => {
    const authUser = wtGetFromLocalStorage("elfa_user_data", null);
    return authUser ? (
      <Navigate
        to={
          +authUser.role === 2 ? "/Evsystem/Dealers" : "/Evsystem/BikeDetails"
        }
        replace
      />
    ) : (
      <Login />
    );
  };

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/about-us" element={<OurStory />} />
      <Route path="/dealers" element={<Dealers />} />
      <Route path="/book-a-test-drive" element={<TestDrive />} />
      <Route path="/ev-1" element={<EV1 />} />
      <Route path="/ev-125" element={<EV125 />} />
      <Route path="/ev-500" element={<EV500 />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/faqs" element={<FAQ />} />
      <Route path="/referral" element={<Referral />} />

      {/* Ev system routes */}
      <Route path="/Evsystem/login" element={<CheckLogin />} />

      <Route
        path="*"
        element={
          <AuthenticatedRoutes>
            <DefaultLayout />
          </AuthenticatedRoutes>
        }
      ></Route>

      <Route
        exact
        path="/Evsystem/404"
        name="Not Found"
        element={<Page404 />}
      />
    </Routes>
  );
}

export default Router;
