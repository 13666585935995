import React from 'react'
import { Layout, Dropdown, Space, Image } from 'antd';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { doLogout } from '../helpers/commons/ApiWrapper';

const { Header } = Layout;


const items = [
  {
    label: 'Logout',
    key: '3',
    icon: <LogoutOutlined />,
  },
];


const AppHeader = ({ colorBgContainer }) => {

  const handleLogout = async () => {
    // Dispatch the action to clear the user from Redux state
    doLogout();
  };


  const handleMenuClick = (e) => {
    handleLogout();
  };
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };


  return (
    <Header
      style={{
        padding: 2,
        background: colorBgContainer,
      }}
    >
      <div>
        <Image
          width={150}
          style={{ padding: 10 }}
          preview={false}
          src={`/images/ELFA-Logo-white.webp`}
        />
      </div>

      <div className="logout-dropdown" style={{ position: 'absolute', top: 5, right: 20 }}>
        <Space wrap>
          <Dropdown.Button menu={menuProps} placement="bottomRight" icon={<UserOutlined />}>
          </Dropdown.Button>
        </Space>
      </div>

    </Header>
  )
}

export default AppHeader
