import React from 'react'
import { Layout } from 'antd';

const { Footer } = Layout;

const AppFooter = () => {
  return (
    <Footer style={{ textAlign: 'center', padding: "10px 50px" }}>
      Elfa ©{new Date().getFullYear()} All rights reserved.
    </Footer>
  )
}

export default React.memo(AppFooter)
